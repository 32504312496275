import { RoleEnum } from "@shared/client/lib";
import { DropDownListItem } from "@shared/components/DropDown";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class ProjectInfoStateHandler {
  loadProjectInfoScreen(
    this: AppStateHandler,
    projectId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.changeScreen(ScreenState.Projects_ProjectInfo, callback);
    this.state.projectInfoScreen = {
      loadingForm: true,
      loadingRoles: true,
      formError: "",
      projectId: null,
      projectName: "",
      description: "",
      typeProject: 0,
      projectNumber: "",
      subregion: 0,
      organisation: "",
      projectManager: "",
      author: "",
      lastEdited: null,
      lastModifiedByNameFull: "",
      userRoles: [],
      typeProjectList: [
        {
          key: 0,
          name: "Aan het laden...",
          disabled: true,
        },
      ],
      subregions: [
        {
          key: 0,
          name: "Aan het laden...",
          disabled: true,
        },
      ],
      projectRole: null,
    };
    callback(this.state);

    let oneFinised = false; // fetch the TypeProject and subregions first then fetch project info => can be optimized

    // Get typeProject
    this.typeProjectApi
      .apiGrexmanagerTypeProjectList()
      .then((typeProject) => {
        this.state.projectInfoScreen.typeProjectList = typeProject.map((x) => {
          const item: DropDownListItem = {
            key: x.id,
            name: x.description,
            disabled: false,
          };
          return item;
        });
        if (this.state.projectInfoScreen.typeProjectList.length > 0) {
          this.state.projectInfoScreen.typeProject =
            this.state.projectInfoScreen.typeProjectList[0].key;
        }
        callback(this.state);

        if (!oneFinised) {
          oneFinised = true;
        } else {
          this.projectApi
            .apiGrexmanagerProjectRetrieve({ id: projectId })
            .then((projectInfo) => {
              this.state.projectInfoScreen = {
                loadingForm: false,
                loadingRoles: false,
                formError: "",
                projectId: projectInfo.id,
                projectName: projectInfo.name,
                description: projectInfo.description || "",
                typeProject: projectInfo.typeProject,
                projectNumber: projectInfo.number || "",
                subregion: projectInfo.subregion,
                organisation: projectInfo.organisation || "",
                projectManager: projectInfo.projectmanager || "",
                author: projectInfo.authorNameFull,
                lastEdited: projectInfo.timestampLastModified,
                lastModifiedByNameFull: projectInfo.lastModifiedByNameFull,
                userRoles: projectInfo.roles,
                subregions: this.state.projectInfoScreen.subregions,
                typeProjectList: this.state.projectInfoScreen.typeProjectList,
                projectRole: projectInfo.role,
              };
              callback(this.state);
            })
            .catch((error) => {
              // TODO: Handle error
              console.log(error);

              this.state.projectInfoScreen.formError =
                "Fout bij het laden van het project.";
              callback(this.state);
              setTimeout(() => {
                this.state.projectInfoScreen.formError = "";
                callback(this.state);
              }, 5000);
            });
        }
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectInfoScreen.formError =
          "Fout bij het laden van projecttypen.";
        callback(this.state);
        setTimeout(() => {
          this.state.projectInfoScreen.formError = "";
          callback(this.state);
        }, 5000);
      });

    // Get subregions
    this.subregionApi
      .apiGrexmanagerSubregionList()
      .then((subregions) => {
        this.state.projectInfoScreen.subregions = subregions.map((x) => {
          const item: DropDownListItem = {
            key: x.id,
            name: x.description,
            disabled: false,
          };
          return item;
        });
        if (this.state.projectInfoScreen.subregions.length > 0) {
          this.state.newProjectScreen.subregion =
            this.state.projectInfoScreen.subregions[0].key;
        }
        callback(this.state);

        if (!oneFinised) {
          oneFinised = true;
        } else {
          this.projectApi
            .apiGrexmanagerProjectRetrieve({ id: projectId })
            .then((projectInfo) => {
              this.state.projectInfoScreen = {
                loadingForm: false,
                loadingRoles: false,
                formError: "",
                projectId: projectInfo.id,
                projectName: projectInfo.name,
                description: projectInfo.description || "",
                typeProject: projectInfo.typeProject,
                projectNumber: projectInfo.number || "",
                subregion: projectInfo.subregion,
                organisation: projectInfo.organisation || "",
                projectManager: projectInfo.projectmanager || "",
                author: projectInfo.authorNameFull,
                lastEdited: projectInfo.timestampLastModified,
                lastModifiedByNameFull: projectInfo.lastModifiedByNameFull,
                userRoles: projectInfo.roles,
                subregions: this.state.projectInfoScreen.subregions,
                typeProjectList: this.state.projectInfoScreen.typeProjectList,
                projectRole: projectInfo.role,
              };
              callback(this.state);
            })
            .catch((error) => {
              // TODO: Handle error
              console.log(error);

              this.state.projectInfoScreen.formError =
                "Fout bij het laden van het project.";
              callback(this.state);
              setTimeout(() => {
                this.state.projectInfoScreen.formError = "";
                callback(this.state);
              }, 5000);
            });
        }
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectInfoScreen.formError =
          "Fout bij het laden van de deelgebieden.";
        callback(this.state);
        setTimeout(() => {
          this.state.projectInfoScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  projectInfoUpdateForm(
    this: AppStateHandler,
    newFormValues: {
      projectName?: string;
      description?: string;
      typeProject?: number;
      projectNumber?: string;
      organisation?: string;
      projectManager?: string;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.projectName !== undefined) {
      this.state.projectInfoScreen.projectName = newFormValues.projectName;
    }
    if (newFormValues.description !== undefined) {
      this.state.projectInfoScreen.description = newFormValues.description;
    }
    if (newFormValues.typeProject !== undefined) {
      this.state.projectInfoScreen.typeProject = newFormValues.typeProject;
    }
    if (newFormValues.projectNumber !== undefined) {
      this.state.projectInfoScreen.projectNumber = newFormValues.projectNumber;
    }
    if (newFormValues.organisation !== undefined) {
      this.state.projectInfoScreen.organisation = newFormValues.organisation;
    }
    if (newFormValues.projectManager !== undefined) {
      this.state.projectInfoScreen.projectManager =
        newFormValues.projectManager;
    }
    callback(this.state);
  }

  projectInfoSubregionChanged(
    this: AppStateHandler,
    newSubregionId: number,
    callback: (newState: AppStateType) => void
  ) {
    // this.state.projectInfoScreen.userRoles = [];
    this.state.projectInfoScreen.subregion = newSubregionId;
    this.state.projectInfoScreen.loadingRoles = true;
    callback(this.state);

    this.subregionApi
      .apiGrexmanagerSubregionRolesList({
        id: this.state.projectInfoScreen.subregion,
      })
      .then((userRoles) => {
        this.state.projectInfoScreen.userRoles = userRoles;
        this.state.projectInfoScreen.loadingRoles = false;
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectInfoScreen.formError =
          "Fout bij het laden van de gebruikersrechten voor deelgebeiden.";
        callback(this.state);
        setTimeout(() => {
          this.state.projectInfoScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  projectInfoChangeRole(
    this: AppStateHandler,
    userId: number,
    newRole: RoleEnum,
    callback: (newState: AppStateType) => void
  ) {
    const roleObjectIndex = this.state.projectInfoScreen.userRoles.findIndex(
      (object) => {
        return object.id === userId;
      }
    );
    if (roleObjectIndex > -1) {
      this.state.projectInfoScreen.userRoles[roleObjectIndex].role = newRole;
      callback(this.state);
    }
  }

  projectInfoSave(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.projectInfoScreen.projectName === "" ||
      this.state.projectInfoScreen.typeProject <= 0 ||
      this.state.projectInfoScreen.subregion <= 0 ||
      this.state.projectInfoScreen.userRoles.length === 0
    ) {
      this.state.projectInfoScreen.formError =
        "Vul alle velden in die gemarkeerd zijn met een *";
      callback(this.state);
      setTimeout(() => {
        this.state.projectInfoScreen.formError = "";
        callback(this.state);
      }, 5000);
    } else if (this.state.projectInfoScreen.projectName.includes("/")) {
      this.state.projectInfoScreen.formError =
        "De naam van het project mag geen '/' bevatten.";
      callback(this.state);
      setTimeout(() => {
        this.state.projectInfoScreen.formError = "";
        callback(this.state);
      }, 5000);
    } else {
      this.state.loading = true;
      callback(this.state);

      if (this.state.projectInfoScreen.projectId) {
        this.projectApi
          .apiGrexmanagerProjectUpdate({
            id: this.state.projectInfoScreen.projectId,
            projectUpdateRequest: {
              description:
                this.state.projectInfoScreen.description === ""
                  ? null
                  : this.state.projectInfoScreen.description,
              organisation:
                this.state.projectInfoScreen.organisation === ""
                  ? null
                  : this.state.projectInfoScreen.organisation,
              name: this.state.projectInfoScreen.projectName,
              number:
                this.state.projectInfoScreen.projectNumber === ""
                  ? null
                  : this.state.projectInfoScreen.projectNumber,
              projectmanager:
                this.state.projectInfoScreen.projectManager === ""
                  ? null
                  : this.state.projectInfoScreen.projectManager,
              subregion: this.state.projectInfoScreen.subregion,
              typeProject: this.state.projectInfoScreen.typeProject,
              roles: this.state.projectInfoScreen.userRoles.map((x) => {
                return { id: x.id, role: x.role };
              }),
            },
          })
          .then(() => {
            this.state.loading = false;
            callback(this.state);
            this.changeScreen(ScreenState.Projects, callback);
          })
          .catch((error) => {
            console.log(error);
            let message = "Er is een fout opgetreden tijdens het opslaan.";
            if (error.response.status === 409) {
              message = "Er bestaat al een project met deze naam.";
            }
            this.state.projectInfoScreen.formError = message;
            this.state.loading = false;
            callback(this.state);
            setTimeout(() => {
              this.state.projectInfoScreen.formError = "";
              callback(this.state);
            }, 5000);
          });
      }
    }
  }
}
