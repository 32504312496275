/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.11.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParameterList } from './ParameterList';
import {
    ParameterListFromJSON,
    ParameterListFromJSONTyped,
    ParameterListToJSON,
} from './ParameterList';

/**
 * 
 * @export
 * @interface ParameterCreateRequest
 */
export interface ParameterCreateRequest {
    /**
     * 
     * @type {Array<ParameterList>}
     * @memberof ParameterCreateRequest
     */
    parameters: Array<ParameterList>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParameterCreateRequest
     */
    models: Array<number>;
}

/**
 * Check if a given object implements the ParameterCreateRequest interface.
 */
export function instanceOfParameterCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parameters" in value;
    isInstance = isInstance && "models" in value;

    return isInstance;
}

export function ParameterCreateRequestFromJSON(json: any): ParameterCreateRequest {
    return ParameterCreateRequestFromJSONTyped(json, false);
}

export function ParameterCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': ((json['parameters'] as Array<any>).map(ParameterListFromJSON)),
        'models': json['models'],
    };
}

export function ParameterCreateRequestToJSON(value?: ParameterCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': ((value.parameters as Array<any>).map(ParameterListToJSON)),
        'models': value.models,
    };
}

