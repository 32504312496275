/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.11.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoleEnum } from './RoleEnum';
import {
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface SubregionListRolesResponse
 */
export interface SubregionListRolesResponse {
    /**
     * 
     * @type {number}
     * @memberof SubregionListRolesResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SubregionListRolesResponse
     */
    nameFull: string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof SubregionListRolesResponse
     */
    role: RoleEnum;
}

/**
 * Check if a given object implements the SubregionListRolesResponse interface.
 */
export function instanceOfSubregionListRolesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "nameFull" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function SubregionListRolesResponseFromJSON(json: any): SubregionListRolesResponse {
    return SubregionListRolesResponseFromJSONTyped(json, false);
}

export function SubregionListRolesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubregionListRolesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'nameFull': json['name_full'],
        'role': RoleEnumFromJSON(json['role']),
    };
}

export function SubregionListRolesResponseToJSON(value?: SubregionListRolesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name_full': value.nameFull,
        'role': RoleEnumToJSON(value.role),
    };
}

