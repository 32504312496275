import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class NewModelStateHandler {
  loadNewModelScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.projectScreen.selectedVersionModel) {
      this.state.screenState = ScreenState.Projects_NewModel;
      this.state.loading = false;
      this.state.newModelScreen = {
        loadingForm: true,
        formError: "",
        modelName: "",
        description: "",
        modelTypes: [
          {
            key: 0,
            name: "Aan het laden...",
            disabled: true,
          },
        ],
        modelType: 0,
      };
      callback(this.state);

      this.templateModelApi
        .apiGrexmanagerTemplateModelList()
        .then((templateModels) => {
          this.state.newModelScreen.modelTypes = templateModels.map(
            (templateModel) => {
              return {
                key: templateModel.id,
                name: templateModel.name,
                disabled: false,
              };
            }
          );
          if (this.state.newModelScreen.modelTypes.length >= 1) {
            this.state.newModelScreen.modelType =
              this.state.newModelScreen.modelTypes[0].key;
          }
          this.state.newModelScreen.loadingForm = false;
          callback(this.state);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);
          this.state.newModelScreen.formError =
            "Sjablonen konden niet geladen worden.";
          callback(this.state);
          setTimeout(() => {
            this.state.newModelScreen.formError = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  newModelUpdateForm(
    this: AppStateHandler,
    newFormValues: {
      modelName?: string;
      description?: string;
      modelType?: number;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.modelName !== undefined) {
      this.state.newModelScreen.modelName = newFormValues.modelName;
    }
    if (newFormValues.description !== undefined) {
      this.state.newModelScreen.description = newFormValues.description;
    }
    if (newFormValues.modelType !== undefined) {
      this.state.newModelScreen.modelType = newFormValues.modelType;
    }
    callback(this.state);
  }

  newModelSave(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.newModelScreen.modelName === "" ||
      this.state.newModelScreen.modelType < 0
    ) {
      this.state.newModelScreen.formError =
        "Vul alle velden in die gemarkeerd zijn met een *";
      callback(this.state);
      setTimeout(() => {
        this.state.newModelScreen.formError = "";
        callback(this.state);
      }, 5000);
    } else if (this.state.newModelScreen.modelName.includes("/")) {
      this.state.newModelScreen.formError =
        "De naam van het model mag geen '/' bevatten.";
      callback(this.state);
      setTimeout(() => {
        this.state.newModelScreen.formError = "";
        callback(this.state);
      }, 5000);
    } else {
      this.state.loading = true;
      callback(this.state);

      if (this.state.projectScreen.selectedVersionModel) {
        this.modelApi
          .apiGrexmanagerModelCreate({
            modelCreateRequest: {
              description:
                this.state.newModelScreen.description === ""
                  ? null
                  : this.state.newModelScreen.description,
              name: this.state.newModelScreen.modelName,
              typeModel: this.state.newModelScreen.modelType,
              version: this.state.projectScreen.selectedVersionModel,
            },
          })
          .then((_) => {
            this.state.loading = false;
            callback(this.state);
            this.changeScreen(ScreenState.Projects, callback);
          })
          .catch((error) => {
            console.log(error);
            let message = "Er is een fout opgetreden tijdens het opslaan.";
            if (error.response.status === 409) {
              message = "Er bestaat al een model met deze naam.";
            }
            this.state.newModelScreen.formError = message;
            this.state.loading = false;
            callback(this.state);
            setTimeout(() => {
              this.state.newModelScreen.formError = "";
              callback(this.state);
            }, 5000);
          });
      }
    }
  }
}
