import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import Button from "@shared/components/Button";
import ButtonList from "@shared/components/ButtonList";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./UserScreen.css";

interface UserScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class UserScreen extends React.Component<UserScreenProps> {
  render() {
    return (
      <div id="UserScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Gebruikersinstellingen",
            },
          ]}
        ></BreadCrumbs>
        {this.props.state.currentUser.isSuperAdmin === true ? (
          <>
            <Section>
              <table className="UserScreen-Table">
                <tbody>
                  <SectionTitle subtitle={true}>SuperAdmin info</SectionTitle>
                  <tr>
                    <td>Voornaam</td>
                    <td>{this.props.state.userScreen.firstName}</td>
                  </tr>
                  <tr>
                    <td>Achternaam</td>
                    <td>{this.props.state.userScreen.lastName}</td>
                  </tr>
                  <tr>
                    <td>E-mail</td>
                    <td>{this.props.state.userScreen.email}</td>
                  </tr>
                </tbody>
              </table>
            </Section>
          </>
        ) : (
          <>
            <Section>
              {(() => {
                if (this.props.state.userScreen.errorMessage !== "") {
                  return (
                    <Alert
                      Error={true}
                      Message={this.props.state.userScreen.errorMessage}
                    ></Alert>
                  );
                } else if (this.props.state.userScreen.infoMessage !== "") {
                  return (
                    <Alert
                      Error={false}
                      Message={this.props.state.userScreen.infoMessage}
                    ></Alert>
                  );
                }
              })()}
              <table className="UserScreen-Table">
                <tbody>
                  <tr>
                    <td>Voornaam</td>
                    <td>
                      <TextInput
                        type={TextInputType.Text}
                        placeholder="Voornaam"
                        onChange={(newFirstName) => {
                          this.props.stateHandler.updateUserForm(
                            { firstName: newFirstName },
                            this.props.updateStateCallback
                          );
                        }}
                        value={this.props.state.userScreen.firstName}
                      ></TextInput>
                    </td>
                  </tr>
                  <tr>
                    <td>Achternaam</td>
                    <td>
                      <TextInput
                        type={TextInputType.Text}
                        placeholder="Achternaam"
                        onChange={(newLastName) => {
                          this.props.stateHandler.updateUserForm(
                            { lastName: newLastName },
                            this.props.updateStateCallback
                          );
                        }}
                        value={this.props.state.userScreen.lastName}
                      ></TextInput>
                    </td>
                  </tr>
                  <tr>
                    <td>E-mailadres</td>
                    <td>
                      <TextInput
                        type={TextInputType.Email}
                        placeholder="E-mailadres"
                        onChange={(newEmail) => {
                          this.props.stateHandler.updateUserForm(
                            { email: newEmail },
                            this.props.updateStateCallback
                          );
                        }}
                        value={this.props.state.userScreen.email}
                      ></TextInput>
                    </td>
                  </tr>
                  <Button
                    onClick={() => {
                      this.props.stateHandler.updateUser(
                        this.props.updateStateCallback
                      );
                    }}
                  >
                    Wijzigingen opslaan
                  </Button>
                </tbody>
              </table>
            </Section>

            <Section>
              <table className="UserScreen-Table">
                <tbody>
                  <tr>
                    <td>Multi-Factor Authenticatie (MFA)</td>
                    <td>
                      {(() => {
                        if (this.props.state.userScreen.mfaVerified) {
                          return "Ingeschakeld";
                        } else {
                          return "Uitgeschakeld";
                        }
                      })()}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {(() => {
                        if (this.props.state.userScreen.mfaVerified) {
                          return (
                            <ButtonList
                              data={[
                                {
                                  title: "Resetten",
                                  callback: () => {
                                    this.props.stateHandler.changeScreen(
                                      ScreenState.SetupMfa,
                                      this.props.updateStateCallback
                                    );
                                  },
                                },
                                {
                                  title: "Uitzetten",
                                  callback: () => {
                                    this.props.stateHandler.disableMFA(
                                      this.props.updateStateCallback
                                    );
                                  },
                                },
                              ]}
                            ></ButtonList>
                          );
                        } else {
                          return (
                            <Button
                              onClick={() => {
                                this.props.stateHandler.changeScreen(
                                  ScreenState.SetupMfa,
                                  this.props.updateStateCallback
                                );
                              }}
                            >
                              Instellen
                            </Button>
                          );
                        }
                      })()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Section>

            <Section>
              {(() => {
                if (
                  this.props.state.userNewPasswordScreen.newPasswordError !== ""
                ) {
                  return (
                    <Alert
                      Error={true}
                      Message={
                        this.props.state.userNewPasswordScreen.newPasswordError
                      }
                    ></Alert>
                  );
                }
                if (
                  this.props.state.userNewPasswordScreen.newPasswordinfo !== ""
                ) {
                  return (
                    <Alert
                      Error={false}
                      Message={
                        this.props.state.userNewPasswordScreen.newPasswordinfo
                      }
                    ></Alert>
                  );
                }
              })()}
              <table className="UserScreen-Table">
                <tbody>
                  <tr>
                    <td>Huidig wachtwoord*</td>
                    <td>
                      <TextInput
                        type={TextInputType.Password}
                        placeholder="Huidig wachtwoord*"
                        value={
                          this.props.state.userNewPasswordScreen.oldPassword
                        }
                        onChange={(oldPassword) => {
                          this.props.stateHandler.changePasswordForm(
                            { oldPassword: oldPassword },
                            this.props.updateStateCallback
                          );
                        }}
                      ></TextInput>
                    </td>
                  </tr>

                  <tr>
                    <td>Nieuw wachtwoord*</td>
                    <td>
                      <TextInput
                        type={TextInputType.NewPassword}
                        placeholder="Nieuw wachtwoord*"
                        value={
                          this.props.state.userNewPasswordScreen.newPassword
                        }
                        onChange={(newPassword) => {
                          this.props.stateHandler.changePasswordForm(
                            { newPassword: newPassword },
                            this.props.updateStateCallback
                          );
                        }}
                      ></TextInput>
                    </td>
                  </tr>

                  <tr>
                    <td>Bevestig wachtwoord*</td>
                    <td>
                      <TextInput
                        type={TextInputType.NewPassword}
                        placeholder="Bevestig wachtwoord*"
                        value={
                          this.props.state.userNewPasswordScreen
                            .newPasswordRepeat
                        }
                        onChange={(newPasswordRepeat) => {
                          this.props.stateHandler.changePasswordForm(
                            { newPasswordRepeat: newPasswordRepeat },
                            this.props.updateStateCallback
                          );
                        }}
                      ></TextInput>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <ul>
                        {this.props.state.PasswordPolicy.notAppliedRequirements.map(
                          (requirement, index) => (
                            <li key={index}>{requirement}</li>
                          )
                        )}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button
                onClick={() => {
                  this.props.stateHandler.changePassword(
                    this.props.updateStateCallback
                  );
                }}
              >
                Opslaan
              </Button>
            </Section>
          </>
        )}
      </div>
    );
  }
}
