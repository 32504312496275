import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./ResetPasswordUserScreen.css";
interface ResetPasswordUserScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ResetPasswordUserScreen extends React.Component<ResetPasswordUserScreenProps> {
  render() {
    return (
      <div id="ResetPasswordUserScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Gebruikers",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.UserList,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title:
                this.props.state.EditUser.firstName +
                " " +
                this.props.state.EditUser.lastName,
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.EditUser,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Wachtwoord resetten",
            },
          ]}
        ></BreadCrumbs>
        <Section>
          <SectionTitle subtitle={true}>Wachtwoord resetten</SectionTitle>
          {(() => {
            if (this.props.state.EditUser.newPasswordError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.EditUser.newPasswordError}
                ></Alert>
              );
            }
          })()}
          <table className="ResetPasswordUserScreen-Table">
            <tbody>
              <tr>
                <td>Wachtwoord*</td>
                <td>
                  <TextInput
                    type={TextInputType.NewPassword}
                    placeholder="Wachtwoord*"
                    value={this.props.state.EditUser.newPassword}
                    onChange={(newvalue) => {
                      this.props.stateHandler.EditUserResetPasswordForm(
                        { newPassword: newvalue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Bevestig wachtwoord*</td>
                <td>
                  <TextInput
                    type={TextInputType.NewPassword}
                    placeholder="Bevestig wachtwoord*"
                    value={this.props.state.EditUser.newPasswordRepeat}
                    onChange={(newvalue) => {
                      this.props.stateHandler.EditUserResetPasswordForm(
                        { newPasswordRepeat: newvalue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <ul>
                    {this.props.state.PasswordPolicy.notAppliedRequirements.map(
                      (requirement, index) => (
                        <li key={index}>{requirement}</li>
                      )
                    )}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>
        <ButtonList
          data={[
            {
              title: "Opslaan",
              callback: () => {
                this.props.stateHandler.EditUserChangePassword(
                  this.props.updateStateCallback
                );
                this.props.updateStateCallback(this.props.state);
              },
            },
            {
              title: "Annuleren",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.EditUser,
                  this.props.updateStateCallback
                );
                this.props.updateStateCallback(this.props.state);
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
