import { ParameterListTypeEnum } from "@shared/client/lib";
import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import CheckBox from "@shared/components/CheckBox";
import DropDown from "@shared/components/DropDown";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import {
  formatDateInput,
  isModelSelectedIn,
} from "@shared/utils/helperFunctions";
import React from "react";
import Section from "src/components/Section";
import {
  IndexToProjectSortOption,
  ProjectSortOptionToDropDown,
  ProjectSortOptionToIndex,
} from "src/helperFunctions";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import "./GlobalParameterScreen.css";

interface GlobalParameterScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class GlobalParameterScreen extends React.Component<GlobalParameterScreenProps> {
  render() {
    return (
      <div id="GlobalParameterScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Configuratie",
            },
            {
              title: "Parameters",
            },
          ]}
        ></BreadCrumbs>

        {(() => {
          if (this.props.state.globalParameterScreen.errorMessage !== "") {
            return (
              <Alert
                Error={true}
                Message={this.props.state.globalParameterScreen.errorMessage}
              ></Alert>
            );
          }
        })()}

        {(() => {
          if (this.props.state.globalParameterScreen.page === 1) {
            return (
              <div id="GlobalParameterScreen-FilterSortHolder">
                <Section id="GlobalParameterScreen-FilterSection">
                  <DropDown
                    list={this.props.state.globalParameterScreen.typeProject}
                    onChange={(newKey) => {
                      this.props.stateHandler.globalParameterScreenOnProjectFilterChange(
                        { typeProject: newKey },
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={
                      this.props.state.globalParameterScreen.filterTypeProject
                    }
                  ></DropDown>
                  <DropDown
                    list={this.props.state.globalParameterScreen.subregions}
                    onChange={(newKey) => {
                      this.props.stateHandler.globalParameterScreenOnProjectFilterChange(
                        { subregion: newKey },
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={
                      this.props.state.globalParameterScreen.filterSubregion
                    }
                  ></DropDown>
                </Section>

                <Section>
                  <DropDown
                    list={ProjectSortOptionToDropDown()}
                    onChange={(newKey) => {
                      this.props.stateHandler.globalParameterScreenUpdateProjectSorting(
                        IndexToProjectSortOption(newKey),
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={ProjectSortOptionToIndex(
                      this.props.state.globalParameterScreen.projectSorting
                    )}
                  ></DropDown>
                </Section>
              </div>
            );
          }
        })()}

        <div id="GlobalParameterScreen-SelectModelSection">
          <div
            id="GlobalParameterScreen-ScrollView"
            className={
              this.props.state.globalParameterScreen.page === 1
                ? "maginBottom"
                : ""
            }
          >
            <div id="GlobalParameterScreen-ScrollHolder">
              {(() => {
                if (this.props.state.globalParameterScreen.page === 0) {
                  return (
                    <>
                      <div className="GlobalParameterScreen-Section">
                        <div className="GlobalParameterScreen-SectionHeader">
                          <div className="GlobalParameterScreen-SectionHeaderTitle">
                            Parameter
                          </div>
                        </div>
                        <div className="GlobalParameterScreen-SectionList">
                          {this.props.state.globalParameterScreen.uniqueParameterTypes.map(
                            (type) => {
                              return (
                                <div
                                  key={type}
                                  className={
                                    type ===
                                    this.props.state.globalParameterScreen
                                      .selectedParameterType
                                      ? "GlobalParameterScreen-ListItem GlobalParameterScreen-ListItemHover GlobalParameterScreen-ListItemSelected"
                                      : "GlobalParameterScreen-ListItem GlobalParameterScreen-ListItemHover"
                                  }
                                  onClick={() => {
                                    this.props.stateHandler.parameterTypeClick(
                                      type,
                                      this.props.updateStateCallback
                                    );
                                  }}
                                >
                                  <span>
                                    {(
                                      type
                                        .toLowerCase()
                                        .charAt(0)
                                        .toUpperCase() +
                                      type.toLowerCase().slice(1)
                                    ).replaceAll("_", " ")}
                                  </span>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>

                      <div className="GlobalParameterScreen-Divider"></div>

                      <div className="GlobalParameterScreen-Section">
                        <div className="GlobalParameterScreen-SectionHeader">
                          <div className="GlobalParameterScreen-SectionHeaderTitle">
                            Waarde
                          </div>
                          {(() => {
                            if (
                              this.props.state.globalParameterScreen
                                .selectedParameterType &&
                              (this.props.state.globalParameterScreen
                                .selectedParameterType ===
                                ParameterListTypeEnum.ToekomstigeIndexGebruiker ||
                                this.props.state.globalParameterScreen
                                  .selectedParameterType ===
                                  ParameterListTypeEnum.HistorischeIndexGebruiker)
                            ) {
                              return (
                                <div
                                  className="GlobalParameterScreen-SectionHeaderButton"
                                  onClick={() => {
                                    this.props.stateHandler.globalParameterAddColumn(
                                      this.props.updateStateCallback
                                    );
                                  }}
                                >
                                  +
                                </div>
                              );
                            }
                          })()}
                        </div>
                        <div className="GlobalParameterScreen-SectionList">
                          {(() => {
                            if (
                              this.props.state.globalParameterScreen
                                .selectedParameterType &&
                              (this.props.state.globalParameterScreen
                                .selectedParameterType ===
                                ParameterListTypeEnum.ToekomstigeIndexGebruiker ||
                                this.props.state.globalParameterScreen
                                  .selectedParameterType ===
                                  ParameterListTypeEnum.HistorischeIndexGebruiker)
                            ) {
                              return (
                                <>
                                  <div className="GlobalParameterScreen-SectionListScrollHolder">
                                    <div className="GlobalParameterScreen-ColumnButtonHolder"></div>
                                    {(() => {
                                      return this.props.state.globalParameterScreen.parameters.map(
                                        (parameter, index) => {
                                          if (
                                            parameter.type ===
                                              this.props.state
                                                .globalParameterScreen
                                                .selectedParameterType &&
                                            parameter.rowIndex === 0
                                          ) {
                                            return (
                                              <div
                                                key={index}
                                                className="GlobalParameterScreen-ListItem"
                                              >
                                                <div className="GlobalParameterScreen-ListItemTitle">
                                                  {parameter.periodType
                                                    ? (
                                                        parameter.periodType
                                                          .toLowerCase()
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                        parameter.periodType
                                                          .toLowerCase()
                                                          .slice(1)
                                                      ).replaceAll("_", " ")
                                                    : ""}
                                                  {parameter.year || ""}
                                                </div>
                                              </div>
                                            );
                                          } else {
                                            return <></>;
                                          }
                                        }
                                      );
                                    })()}
                                  </div>
                                  {(() => {
                                    const maxIndex = Math.max(
                                      ...this.props.state.globalParameterScreen.parameters
                                        .filter(
                                          (parameter, index, list) =>
                                            parameter.type ===
                                            this.props.state
                                              .globalParameterScreen
                                              .selectedParameterType
                                        )
                                        .map((p) => p.rowIndex ?? -1)
                                    );
                                    const indexes = Array.from(
                                      {
                                        length: maxIndex + 1,
                                      },
                                      (_, i) => i
                                    );
                                    console.log(maxIndex, indexes);

                                    return indexes.map((rowIndex) => {
                                      return (
                                        <div
                                          key={rowIndex}
                                          className="GlobalParameterScreen-SectionListScrollHolder"
                                        >
                                          <div className="GlobalParameterScreen-ColumnButtonHolder">
                                            {(() => {
                                              if (rowIndex > 0) {
                                                return (
                                                  <div
                                                    className="GlobalParameterScreen-ColumnButton"
                                                    onClick={() => {
                                                      this.props.stateHandler.globalParameterRemoveColumn(
                                                        rowIndex,
                                                        this.props
                                                          .updateStateCallback
                                                      );
                                                    }}
                                                  >
                                                    -
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </div>
                                          {(() => {
                                            return this.props.state.globalParameterScreen.parameters.map(
                                              (parameter, index) => {
                                                if (
                                                  parameter.type ===
                                                    this.props.state
                                                      .globalParameterScreen
                                                      .selectedParameterType &&
                                                  parameter.rowIndex ===
                                                    rowIndex
                                                ) {
                                                  return (
                                                    <div
                                                      key={index}
                                                      className="GlobalParameterScreen-ListItem"
                                                    >
                                                      <TextInput
                                                        type={
                                                          TextInputType.Text
                                                        }
                                                        value={
                                                          parameter.valueString
                                                            ? parameter.valueString
                                                            : ""
                                                        }
                                                        onChange={(
                                                          newValue
                                                        ) => {
                                                          this.props.stateHandler.onGlobalParameterInputChange(
                                                            index,
                                                            newValue,
                                                            this.props
                                                              .updateStateCallback
                                                          );
                                                        }}
                                                      ></TextInput>
                                                      <div className="GlobalParameterScreen-ListItemDetail">
                                                        {parameter.valueString
                                                          ? `${
                                                              Math.floor(
                                                                parseFloat(
                                                                  parameter.valueString
                                                                ) * 10000
                                                              ) / 100
                                                            }%`
                                                          : ""}
                                                      </div>
                                                    </div>
                                                  );
                                                } else {
                                                  return <></>;
                                                }
                                              }
                                            );
                                          })()}
                                        </div>
                                      );
                                    });
                                  })()}
                                </>
                              );
                            } else if (
                              this.props.state.globalParameterScreen
                                .selectedParameterType
                            ) {
                              return this.props.state.globalParameterScreen.parameters.map(
                                (parameter, index) => {
                                  if (
                                    parameter.type ===
                                    this.props.state.globalParameterScreen
                                      .selectedParameterType
                                  ) {
                                    return (
                                      <div
                                        key={index}
                                        className="GlobalParameterScreen-ListItem"
                                      >
                                        <div className="GlobalParameterScreen-ListItemTitle">
                                          {parameter.periodType
                                            ? (
                                                parameter.periodType
                                                  .toLowerCase()
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                parameter.periodType
                                                  .toLowerCase()
                                                  .slice(1)
                                              ).replaceAll("_", " ")
                                            : ""}
                                        </div>
                                        <TextInput
                                          type={
                                            parameter.valueDate
                                              ? TextInputType.Date
                                              : TextInputType.Text
                                          }
                                          value={
                                            (parameter.valueString
                                              ? parameter.valueString
                                              : "") +
                                            (parameter.valueDate
                                              ? formatDateInput(
                                                  parameter.valueDate
                                                )
                                              : "")
                                          }
                                          onChange={(newValue) => {
                                            this.props.stateHandler.onGlobalParameterInputChange(
                                              index,
                                              newValue,
                                              this.props.updateStateCallback
                                            );
                                          }}
                                        ></TextInput>
                                        {(() => {
                                          const paramaterTypesDates: ParameterListTypeEnum[] =
                                            [
                                              ParameterListTypeEnum.Eindwaardedatum,
                                              ParameterListTypeEnum.NcwDatum,
                                              ParameterListTypeEnum.Prijspeildatum,
                                              ParameterListTypeEnum.StartdatumGrondexploitatieBoekwaardeVanaf,
                                            ];

                                          if (
                                            this.props.state
                                              .globalParameterScreen
                                              .selectedParameterType &&
                                            !paramaterTypesDates.includes(
                                              this.props.state
                                                .globalParameterScreen
                                                .selectedParameterType
                                            )
                                          ) {
                                            return (
                                              <div className="GlobalParameterScreen-ListItemDetail">
                                                {parameter.valueString
                                                  ? `${
                                                      Math.floor(
                                                        parseFloat(
                                                          parameter.valueString
                                                        ) * 10000
                                                      ) / 100
                                                    }%`
                                                  : ""}
                                              </div>
                                            );
                                          }
                                        })()}
                                      </div>
                                    );
                                  } else {
                                    return <></>;
                                  }
                                }
                              );
                            }
                          })()}
                        </div>
                      </div>
                    </>
                  );
                } else if (this.props.state.globalParameterScreen.page === 1) {
                  return (
                    <>
                      <div className="GlobalParameterScreen-Section">
                        <div className="GlobalParameterScreen-SectionHeader">
                          <div className="GlobalParameterScreen-SectionHeaderTitle">
                            {this.props.state.globalParameterScreen
                              .loadingSpinner ? (
                              <div className="spinner"></div>
                            ) : (
                              <CheckBox
                                checked={
                                  this.props.state.globalParameterScreen
                                    .allProjectsSelected
                                }
                                onClick={(checked) => {
                                  this.props.stateHandler.globalParameterScreenToggleSelectAllButton(
                                    this.props.updateStateCallback
                                  );
                                }}
                              ></CheckBox>
                            )}
                            Projecten
                          </div>
                        </div>
                        <div className="GlobalParameterScreen-SectionList">
                          {this.props.state.globalParameterScreen.projectsList.map(
                            (item) => {
                              if (item.visible) {
                                return (
                                  <div
                                    key={item.id}
                                    className={
                                      item.id ===
                                      this.props.state.globalParameterScreen
                                        .selectedProject
                                        ? "GlobalParameterScreen-ListItem GlobalParameterScreen-ListItemSelected"
                                        : "GlobalParameterScreen-ListItem"
                                    }
                                    onClick={() => {
                                      this.props.stateHandler.globalParameterScreenOnProjectClick(
                                        item.id,
                                        this.props.updateStateCallback
                                      );
                                    }}
                                  >
                                    <CheckBox
                                      checked={isModelSelectedIn(
                                        this.props.state.globalParameterScreen
                                          .selectedModels,
                                        { project: item.id }
                                      )}
                                      onClick={(checked) => {
                                        this.props.stateHandler.globalParameterScreenToggleModel(
                                          { project: item.id },
                                          this.props.updateStateCallback
                                        );
                                      }}
                                    ></CheckBox>
                                    <span>{item.name}</span>
                                    <span className="GlobalParameterScreen-SubText">
                                      {item.number ? " - " + item.number : ""}
                                    </span>
                                  </div>
                                );
                              } else {
                                return <></>;
                              }
                            }
                          )}
                        </div>
                        <div
                          className={
                            this.props.state.globalParameterScreen
                              .loadingProjects
                              ? "GlobalParameterScreen-SectionDisabledOverlay disabled"
                              : "GlobalParameterScreen-SectionDisabledOverlay"
                          }
                        ></div>
                      </div>

                      <div className="GlobalParameterScreen-Divider"></div>

                      <div className="GlobalParameterScreen-Section">
                        <div className="GlobalParameterScreen-SectionHeader">
                          <div className="GlobalParameterScreen-SectionHeaderTitle">
                            Versies
                          </div>
                        </div>
                        <div className="GlobalParameterScreen-SectionList">
                          {this.props.state.globalParameterScreen.versionsList.map(
                            (item) => {
                              return (
                                <div
                                  key={item.id}
                                  className={
                                    item.id ===
                                    this.props.state.globalParameterScreen
                                      .selectedVersionModel
                                      ? "GlobalParameterScreen-ListItem GlobalParameterScreen-ListItemSelected"
                                      : "GlobalParameterScreen-ListItem"
                                  }
                                  onClick={() => {
                                    this.props.stateHandler.globalParameterScreenOnVersionModelClick(
                                      item.id,
                                      this.props.updateStateCallback
                                    );
                                  }}
                                >
                                  <CheckBox
                                    checked={isModelSelectedIn(
                                      this.props.state.globalParameterScreen
                                        .selectedModels,
                                      { versionModel: item.id }
                                    )}
                                    onClick={(checked) => {
                                      this.props.stateHandler.globalParameterScreenToggleModel(
                                        { versionModel: item.id },
                                        this.props.updateStateCallback
                                      );
                                    }}
                                  ></CheckBox>
                                  <span>{item.name}</span>
                                </div>
                              );
                            }
                          )}
                        </div>
                        <div
                          className={
                            this.props.state.globalParameterScreen
                              .loadingVersionModels
                              ? "GlobalParameterScreen-SectionDisabledOverlay disabled"
                              : "GlobalParameterScreen-SectionDisabledOverlay"
                          }
                        ></div>
                      </div>

                      <div className="GlobalParameterScreen-Divider"></div>

                      <div className="GlobalParameterScreen-Section">
                        <div className="GlobalParameterScreen-SectionHeader">
                          <div className="GlobalParameterScreen-SectionHeaderTitle">
                            Rekenmodellen
                          </div>
                        </div>
                        <div className="GlobalParameterScreen-SectionList">
                          {this.props.state.globalParameterScreen.modelsList.map(
                            (item) => {
                              return (
                                <div
                                  key={item.id}
                                  className={"GlobalParameterScreen-ListItem"}
                                >
                                  <CheckBox
                                    checked={isModelSelectedIn(
                                      this.props.state.globalParameterScreen
                                        .selectedModels,
                                      { model: item.id }
                                    )}
                                    onClick={(checked) => {
                                      this.props.stateHandler.globalParameterScreenToggleModel(
                                        { model: item.id },
                                        this.props.updateStateCallback
                                      );
                                    }}
                                  ></CheckBox>
                                  <span>{item.name}</span>
                                  <span className="GlobalParameterScreen-SubText">
                                    {" "}
                                    - {item.typeModelName}
                                  </span>
                                </div>
                              );
                            }
                          )}
                        </div>
                        <div
                          className={
                            this.props.state.globalParameterScreen.loadingModels
                              ? "GlobalParameterScreen-SectionDisabledOverlay disabled"
                              : "GlobalParameterScreen-SectionDisabledOverlay"
                          }
                        ></div>
                      </div>
                    </>
                  );
                }
              })()}
            </div>
          </div>
          {(() => {
            if (this.props.state.globalParameterScreen.page === 1) {
              return (
                <>
                  <div className="GlobalParameterScreen-HorizontalDivider"></div>
                  <div id="GlobalParameterScreen-SelectedText">
                    {this.props.state.globalParameterScreen.selectedModels
                      .length === 1
                      ? "1 rekenmodel geselecteerd. "
                      : `${this.props.state.globalParameterScreen.selectedModels.length} rekenmodellen geselecteerd. `}
                  </div>
                </>
              );
            }
          })()}
        </div>

        <div style={{ marginTop: "12px" }}>
          <ButtonList
            data={
              this.props.state.globalParameterScreen.page === 0
                ? [
                    {
                      title: "Volgende",
                      callback: () => {
                        this.props.stateHandler.globalParameterGoToSecondPage(
                          this.props.updateStateCallback
                        );
                      },
                      disabled: false,
                    },
                  ]
                : [
                    {
                      title: "Vorige",
                      callback: () => {
                        this.props.stateHandler.globalParameterGoToFirstPage(
                          this.props.updateStateCallback
                        );
                      },
                      disabled: false,
                    },
                    {
                      title: "Parameters doorvoeren",
                      callback: () => {
                        this.props.stateHandler.globalParameterScreenSubmit(
                          this.props.updateStateCallback
                        );
                      },
                      disabled: false,
                    },
                  ]
            }
          ></ButtonList>
        </div>
      </div>
    );
  }
}
