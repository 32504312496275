import Button from "@shared/components/Button";
import GrexManagerLogo from "@shared/components/GrexManagerLogo";
import SectionTitle from "@shared/components/SectionTitle";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";
import "./LoginSamlScreen.css";
interface LoginSamlScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class LoginSamlScreen extends React.Component<LoginSamlScreenProps> {
  render() {
    return (
      <div id="LoginSamlScreen-Container">
        <div id="LoginSamlScreen-Section">
          <GrexManagerLogo />

          <div id="LoginSamlScreen-LoginHolder">
            <SectionTitle>Inloggen</SectionTitle>

            <Button
              onClick={() => {
                this.props.stateHandler.login_saml();
              }}
            >
              Inloggen met SAML
            </Button>
          </div>

          <div id="LoginSamlScreen-EscapeButton">
            <Button
              onClick={() => {
                this.props.stateHandler.go_to_login(
                  this.props.updateStateCallback
                );
              }}
            ></Button>
          </div>
        </div>
        <div id="Background-Section"></div>
      </div>
    );
  }
}
