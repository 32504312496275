/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.11.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Model
 */
export interface Model {
    /**
     * 
     * @type {number}
     * @memberof Model
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Model
     */
    frozen?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof Model
     */
    readonly timestampLastModified: Date;
    /**
     * 
     * @type {number}
     * @memberof Model
     */
    author: number;
    /**
     * 
     * @type {number}
     * @memberof Model
     */
    lastModifiedBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Model
     */
    typeModel: number;
    /**
     * 
     * @type {number}
     * @memberof Model
     */
    version: number;
    /**
     * 
     * @type {number}
     * @memberof Model
     */
    parameterSet?: number | null;
}

/**
 * Check if a given object implements the Model interface.
 */
export function instanceOfModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "timestampLastModified" in value;
    isInstance = isInstance && "author" in value;
    isInstance = isInstance && "typeModel" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function ModelFromJSON(json: any): Model {
    return ModelFromJSONTyped(json, false);
}

export function ModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Model {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'frozen': !exists(json, 'frozen') ? undefined : json['frozen'],
        'name': json['name'],
        'timestampLastModified': (new Date(json['timestamp_last_modified'])),
        'author': json['author'],
        'lastModifiedBy': !exists(json, 'last_modified_by') ? undefined : json['last_modified_by'],
        'typeModel': json['type_model'],
        'version': json['version'],
        'parameterSet': !exists(json, 'parameter_set') ? undefined : json['parameter_set'],
    };
}

export function ModelToJSON(value?: Model | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'frozen': value.frozen,
        'name': value.name,
        'author': value.author,
        'last_modified_by': value.lastModifiedBy,
        'type_model': value.typeModel,
        'version': value.version,
        'parameter_set': value.parameterSet,
    };
}

