/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.11.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectInfoRoleResponse } from './ProjectInfoRoleResponse';
import {
    ProjectInfoRoleResponseFromJSON,
    ProjectInfoRoleResponseFromJSONTyped,
    ProjectInfoRoleResponseToJSON,
} from './ProjectInfoRoleResponse';
import type { RoleEnum } from './RoleEnum';
import {
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface ProjectInfoResponse
 */
export interface ProjectInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof ProjectInfoResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfoResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfoResponse
     */
    organisation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfoResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfoResponse
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfoResponse
     */
    projectmanager?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectInfoResponse
     */
    subregion: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectInfoResponse
     */
    typeProject: number;
    /**
     * 
     * @type {Date}
     * @memberof ProjectInfoResponse
     */
    readonly timestampLastModified: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfoResponse
     */
    readonly lastModifiedByNameFull: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInfoResponse
     */
    readonly authorNameFull: string;
    /**
     * 
     * @type {Array<ProjectInfoRoleResponse>}
     * @memberof ProjectInfoResponse
     */
    roles: Array<ProjectInfoRoleResponse>;
    /**
     * 
     * @type {RoleEnum}
     * @memberof ProjectInfoResponse
     */
    role: RoleEnum;
}

/**
 * Check if a given object implements the ProjectInfoResponse interface.
 */
export function instanceOfProjectInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "subregion" in value;
    isInstance = isInstance && "typeProject" in value;
    isInstance = isInstance && "timestampLastModified" in value;
    isInstance = isInstance && "lastModifiedByNameFull" in value;
    isInstance = isInstance && "authorNameFull" in value;
    isInstance = isInstance && "roles" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function ProjectInfoResponseFromJSON(json: any): ProjectInfoResponse {
    return ProjectInfoResponseFromJSONTyped(json, false);
}

export function ProjectInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'organisation': !exists(json, 'organisation') ? undefined : json['organisation'],
        'name': json['name'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'projectmanager': !exists(json, 'projectmanager') ? undefined : json['projectmanager'],
        'subregion': json['subregion'],
        'typeProject': json['type_project'],
        'timestampLastModified': (new Date(json['timestamp_last_modified'])),
        'lastModifiedByNameFull': json['last_modified_by_name_full'],
        'authorNameFull': json['author_name_full'],
        'roles': ((json['roles'] as Array<any>).map(ProjectInfoRoleResponseFromJSON)),
        'role': RoleEnumFromJSON(json['role']),
    };
}

export function ProjectInfoResponseToJSON(value?: ProjectInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'organisation': value.organisation,
        'name': value.name,
        'number': value.number,
        'projectmanager': value.projectmanager,
        'subregion': value.subregion,
        'type_project': value.typeProject,
        'roles': ((value.roles as Array<any>).map(ProjectInfoRoleResponseToJSON)),
        'role': RoleEnumToJSON(value.role),
    };
}

