import { RoleEnum } from "@shared/client/lib";
import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import CheckBox from "@shared/components/CheckBox";
import DropDown from "@shared/components/DropDown";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import {
  IndexToRoleEnum,
  RoleEnumToDropDown,
  RoleEnumToIndex,
  RoleProjectEnumToDropDown,
} from "@shared/utils/helperFunctions";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./CreateUserScreen.css";

interface CreateUserScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class CreateUserScreen extends React.Component<CreateUserScreenProps> {
  render() {
    return (
      <div id="CreateUserScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Gebruiker toevoegen",
            },
          ]}
        ></BreadCrumbs>
        <Section>
          {(() => {
            if (this.props.state.NewUser.NewUserError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.NewUser.NewUserError}
                ></Alert>
              );
            }
          })()}
          <table className="CreateUserScreen-Table">
            <tbody>
              <tr>
                <td>Voornaam*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Voornaam"
                    value={this.props.state.NewUser.firstName}
                    onChange={(newvalue) => {
                      this.props.stateHandler.CreateUserForm(
                        { firstName: newvalue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Achternaam*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Achternaam"
                    value={this.props.state.NewUser.lastName}
                    onChange={(newvalue) => {
                      this.props.stateHandler.CreateUserForm(
                        { lastName: newvalue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Email*</td>
                <td>
                  <TextInput
                    type={TextInputType.Email}
                    placeholder="Email"
                    value={this.props.state.NewUser.email}
                    onChange={(newvalue) => {
                      this.props.stateHandler.CreateUserForm(
                        { email: newvalue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Rol*</td>
                <td>
                  <DropDown
                    list={RoleEnumToDropDown()}
                    onChange={(newKey: number) => {
                      this.props.stateHandler.CreateUserForm(
                        { role: IndexToRoleEnum(newKey) },
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={RoleEnumToIndex(this.props.state.NewUser.role)}
                  ></DropDown>
                </td>
              </tr>
              <tr>
                <td>Tijdelijk wachtwoord*</td>
                <td>
                  <TextInput
                    type={TextInputType.NewPassword}
                    placeholder="Tijdelijk wachtwoord"
                    value={this.props.state.NewUser.password}
                    onChange={(newvalue) => {
                      this.props.stateHandler.CreateUserForm(
                        { password: newvalue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Bevestig tijdelijk wachtwoord*</td>
                <td>
                  <TextInput
                    type={TextInputType.NewPassword}
                    placeholder="Bevestig tijdelijk wachtwoord"
                    value={this.props.state.NewUser.passwordRepeat}
                    onChange={(newvalue) => {
                      this.props.stateHandler.CreateUserForm(
                        { passwordRepeat: newvalue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <ul>
                    {this.props.state.PasswordPolicy.notAppliedRequirements.map(
                      (requirement, index) => (
                        <li key={index}>{requirement}</li>
                      )
                    )}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>
        <Section>
          <SectionTitle subtitle={true}>Deelgebieden</SectionTitle>
          <table className="CreateUserScreen-Table">
            <tbody>
              {this.props.state.NewUser.subregions.map((item) => {
                return (
                  <tr key={item.subregionId}>
                    <td>{item.subregionDescription}</td>
                    <td>
                      <CheckBox
                        checked={item.access}
                        onClick={() => {
                          this.props.stateHandler.CreateUserSubregionAccessUpdateForm(
                            item.subregionId,
                            !item.access,
                            this.props.updateStateCallback
                          );
                        }}
                      ></CheckBox>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Section>

        <Section>
          <SectionTitle subtitle={true}>Rapporten met autorisatie</SectionTitle>
          <table className="CreateUserScreen-Table">
            <tbody>
              {this.props.state.NewUser.templateReports.map((item) => {
                return (
                  <tr key={item.templateReportId}>
                    <td>{item.templateReportName}</td>
                    <td>
                      <CheckBox
                        checked={item.access}
                        onClick={() => {
                          this.props.stateHandler.CreateUserTemplateReportAccessUpdateForm(
                            item.templateReportId,
                            !item.access,
                            this.props.updateStateCallback
                          );
                        }}
                      ></CheckBox>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Section>

        <Section>
          <SectionTitle subtitle={true}>Projectrechten</SectionTitle>
          <table className="CreateUserScreen-Table">
            <thead>
              <tr>
                <td>Toepassen op alle projecten</td>
                <td>
                  <DropDown
                    list={[
                      {
                        key: -1,
                        name: "--",
                        disabled: true,
                      },
                      ...RoleProjectEnumToDropDown(),
                    ]}
                    onChange={(newKey: number) => {
                      this.props.stateHandler.CreateUserAllProjectsRoles(
                        IndexToRoleEnum(newKey),
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={
                      this.props.state.NewUser.allProjectRolesDropdown
                        ? RoleEnumToIndex(
                            this.props.state.NewUser.allProjectRolesDropdown
                          )
                        : -1
                    }
                  ></DropDown>
                </td>
              </tr>
              <tr>
                <th>Project</th>
                <th>Rechten</th>
              </tr>
            </thead>
            <tbody>
              {this.props.state.NewUser.projectsRoles.map((item) => {
                return (
                  <tr key={item.projectId}>
                    <td>{item.projectName}</td>
                    <td>
                      <DropDown
                        list={RoleProjectEnumToDropDown()}
                        onChange={(newKey: number) => {
                          this.props.stateHandler.CreateUserProjectRoleUpdateForm(
                            item.projectId,
                            IndexToRoleEnum(newKey),
                            this.props.updateStateCallback
                          );
                        }}
                        selectedKey={RoleEnumToIndex(
                          this.props.state.NewUser.projectsRoles.find(
                            (project) => {
                              return project.projectId === item.projectId;
                            }
                          )?.role as RoleEnum
                        )}
                      ></DropDown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Section>
        <ButtonList
          data={[
            {
              title: "Gebruiker toevoegen",
              callback: () => {
                this.props.stateHandler.CreateUser(
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Annuleren",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.UserList,
                  this.props.updateStateCallback
                );
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
