import { DropDownListItem } from "@shared/components/DropDown";
import { AppStateHandler } from "../../AppStateHandler";
import {
  AppStateType,
  MoveOverviewState,
  PopUpState,
  ProjectSortOption,
  ScreenState,
} from "../../Types";

export class ProjectStateHandler {
  sortProjectList(this: AppStateHandler) {
    // Alphabetical
    if (
      this.state.projectScreen.projectSorting === ProjectSortOption.Alphabetical
    ) {
      this.state.projectScreen.projectsList.sort((a, b) => {
        const nameA = a.name.toLowerCase(); // ignore upper and lowercase
        const nameB = b.name.toLowerCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    // ProjectNumber
    else if (
      this.state.projectScreen.projectSorting ===
      ProjectSortOption.ProjectNumber
    ) {
      this.state.projectScreen.projectsList.sort((a, b) => {
        if (a.number == null && b.number != null) {
          return 1;
        }
        if (a.number != null && b.number == null) {
          return -1;
        }
        if (a.number != null && b.number != null) {
          const numberA = a.number.toLowerCase(); // ignore upper and lowercase
          const numberB = b.number.toLowerCase(); // ignore upper and lowercase
          if (numberA < numberB) {
            return -1;
          }
          if (numberA > numberB) {
            return 1;
          }
        }
        return 0;
      });
    }
    // Last edited
    else if (
      this.state.projectScreen.projectSorting === ProjectSortOption.LastEdited
    ) {
      this.state.projectScreen.projectsList.sort((a, b) => {
        const timeA = a.timestampLastModified; // ignore upper and lowercase
        const timeB = b.timestampLastModified; // ignore upper and lowercase
        if (timeA < timeB) {
          return 1;
        }
        if (timeA > timeB) {
          return -1;
        }
        return 0;
      });
    }
  }

  // project screen
  initProjectScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Projects;
    this.state.projectScreen.projectsList = [];
    this.state.projectScreen.versionsList = [];
    this.state.projectScreen.modelsList = [];
    this.state.projectScreen.loadingProjects = true;
    this.state.projectScreen.loadingVersionModels = true;
    this.state.projectScreen.loadingModels = true;
    this.state.projectScreen.typeProjectList = [];
    this.state.projectScreen.subregions = [];
    callback(this.state);

    // Get typeProject
    this.typeProjectApi
      .apiGrexmanagerTypeProjectList()
      .then((typeProject) => {
        this.state.projectScreen.typeProjectList = [
          {
            key: 0,
            name: "-- alle project typen --",
            disabled: false,
          },
        ].concat(
          typeProject.map((x) => {
            const item: DropDownListItem = {
              key: x.id,
              name: x.description,
              disabled: false,
            };
            return item;
          })
        );
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectScreen.errorMessage =
          "Projecttypen konden niet geladen worden.";
        callback(this.state);
        setTimeout(() => {
          this.state.projectScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });

    // Get subregions
    this.subregionApi
      .apiGrexmanagerSubregionList()
      .then((subregions) => {
        this.state.projectScreen.subregions = [
          {
            key: 0,
            name: "-- alle deelgebieden --",
            disabled: false,
          },
        ].concat(
          subregions.map((x) => {
            const item: DropDownListItem = {
              key: x.id,
              name: x.description,
              disabled: false,
            };
            return item;
          })
        );
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectScreen.errorMessage =
          "Deelgebieden konden niet geladen worden.";
        callback(this.state);
        setTimeout(() => {
          this.state.projectScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });

    this.projectScreenReloadProjects(callback);
  }

  projectScreenReloadProjects(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.projectScreen.projectsList = [];
    this.state.projectScreen.versionsList = [];
    this.state.projectScreen.modelsList = [];
    this.state.projectScreen.loadingProjects = true;
    this.state.projectScreen.loadingVersionModels = true;
    this.state.projectScreen.loadingModels = true;
    callback(this.state);

    // Get projects
    this.projectApi
      .apiGrexmanagerProjectList()
      .then((projectsList) => {
        this.state.projectScreen.projectsList = projectsList.map((item) => {
          return {
            id: item.id,
            name: item.name,
            number: item.number,
            subregion: item.subregion,
            typeProject: item.typeProject,
            timestampLastModified: item.timestampLastModified,
            visible: true,
            role: item.role,
          };
        });
        this.state.projectScreen.loadingProjects = false;
        if (this.state.projectScreen.projectsList.length >= 1) {
          this.sortProjectList();
          this.filterProjectListAndSelectFirst();
        }
        callback(this.state);

        this.projectScreenReloadVersionModels(callback);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectScreen.errorMessage =
          "Projecten konden niet geladen worden.";
        callback(this.state);
        setTimeout(() => {
          this.state.projectScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }

  projectScreenReloadVersionModels(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.projectScreen.versionsList = [];
    this.state.projectScreen.modelsList = [];
    this.state.projectScreen.loadingVersionModels = true;
    this.state.projectScreen.loadingModels = true;
    callback(this.state);

    // Get versions
    if (this.state.projectScreen.selectedProject !== null) {
      this.versionModelApi
        .apiGrexmanagerVersionModelList({
          projectId: this.state.projectScreen.selectedProject,
        })
        .then((versionModelList) => {
          this.state.projectScreen.versionsList = versionModelList;
          this.state.projectScreen.loadingVersionModels = false;

          if (this.state.projectScreen.versionsList.length >= 1) {
            // select first one
            // TODO:
            if (this.state.projectScreen.selectedVersionModel == null) {
              this.state.projectScreen.selectedVersionModel =
                this.state.projectScreen.versionsList[0].id;
            }
          }
          callback(this.state);

          this.projectScreenReloadModels(callback);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.projectScreen.errorMessage =
            "Versies konden niet geladen worden.";
          callback(this.state);
          setTimeout(() => {
            this.state.projectScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  projectScreenReloadModels(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.projectScreen.modelsList = [];
    this.state.projectScreen.loadingModels = true;
    callback(this.state);

    // Get Models
    if (this.state.projectScreen.selectedVersionModel !== null) {
      this.modelApi
        .apiGrexmanagerModelList({
          versionModelId: this.state.projectScreen.selectedVersionModel,
        })
        .then((modelsList) => {
          this.state.projectScreen.modelsList = modelsList;
          this.state.projectScreen.loadingModels = false;
          callback(this.state);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.projectScreen.errorMessage =
            "Rekenmodellen konden niet geladen worden.";
          callback(this.state);
          setTimeout(() => {
            this.state.projectScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  onProjectFilterChange(
    this: AppStateHandler,
    filter: {
      typeProject?: number;
      subregion?: number;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (filter.typeProject !== undefined) {
      this.state.projectScreen.filterTypeProject = filter.typeProject;
    }
    if (filter.subregion !== undefined) {
      this.state.projectScreen.filterSubregion = filter.subregion;
    }
    this.filterProjectListAndSelectFirst();
    callback(this.state);
    this.projectScreenReloadVersionModels(callback);
  }

  filterProjectListAndSelectFirst(this: AppStateHandler) {
    // Make visible or invisible
    this.state.projectScreen.projectsList.forEach((project) => {
      if (
        this.state.projectScreen.filterTypeProject === 0 &&
        this.state.projectScreen.filterSubregion === 0
      ) {
        project.visible = true;
      } else if (
        this.state.projectScreen.filterTypeProject === project.typeProject &&
        this.state.projectScreen.filterSubregion === 0
      ) {
        project.visible = true;
      } else if (
        this.state.projectScreen.filterTypeProject === 0 &&
        this.state.projectScreen.filterSubregion === project.subregion
      ) {
        project.visible = true;
      } else if (
        this.state.projectScreen.filterTypeProject === project.typeProject &&
        this.state.projectScreen.filterSubregion === project.subregion
      ) {
        project.visible = true;
      } else {
        project.visible = false;
      }
    });

    // If the selected project became invisible then select the first visible project and reload versions and models
    if (this.state.projectScreen.selectedProject) {
      const projectIndex = this.state.projectScreen.projectsList.findIndex(
        (object) => {
          return object.id === this.state.projectScreen.selectedProject;
        }
      );
      if (projectIndex > -1) {
        if (!this.state.projectScreen.projectsList[projectIndex].visible) {
          const visibleProjects = this.state.projectScreen.projectsList.filter(
            (object) => {
              return object.visible;
            }
          );
          if (visibleProjects.length >= 1) {
            this.state.projectScreen.selectedProject = visibleProjects[0].id;
            this.state.projectScreen.selectedVersionModel = null;
          } else {
            this.state.projectScreen.selectedProject = null;
            this.state.projectScreen.selectedVersionModel = null;
          }
        }
      }
    } else {
      const visibleProjects = this.state.projectScreen.projectsList.filter(
        (object) => {
          return object.visible;
        }
      );
      if (visibleProjects.length >= 1) {
        this.state.projectScreen.selectedProject = visibleProjects[0].id;
        this.state.projectScreen.selectedVersionModel = null;
      }
    }
  }

  updateProjectSorting(
    this: AppStateHandler,
    projectSorting: ProjectSortOption,
    callback: (newState: AppStateType) => void
  ) {
    this.state.projectScreen.projectSorting = projectSorting;
    this.sortProjectList();
    callback(this.state);
  }

  onProjectClick(
    this: AppStateHandler,
    projectId: number,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.projectScreen.selectedProject !== projectId) {
      // Check if project exists
      const projectIndex = this.state.projectScreen.projectsList.findIndex(
        (object) => {
          return object.id === projectId;
        }
      );
      if (projectIndex > -1) {
        this.state.projectScreen.selectedProject = projectId;
        this.state.projectScreen.versionsList = [];
        this.state.projectScreen.selectedVersionModel = null;
        this.state.projectScreen.modelsList = [];
        this.state.projectScreen.loadingVersionModels = true;
        this.state.projectScreen.loadingModels = true;
        callback(this.state);

        // Get versions
        this.versionModelApi
          .apiGrexmanagerVersionModelList({
            projectId: this.state.projectScreen.selectedProject,
          })
          .then((versionModelList) => {
            this.state.projectScreen.versionsList = versionModelList;
            if (this.state.projectScreen.versionsList.length >= 1) {
              // check if selected version is in new version list => else select first
              const versionIndex =
                this.state.projectScreen.versionsList.findIndex((object) => {
                  return (
                    object.id === this.state.projectScreen.selectedVersionModel
                  );
                });
              if (
                versionIndex === -1 ||
                this.state.projectScreen.selectedVersionModel == null
              ) {
                this.state.projectScreen.selectedVersionModel =
                  this.state.projectScreen.versionsList[0].id;
              }
            }
            this.state.projectScreen.loadingVersionModels = false;
            callback(this.state);

            // Get Models
            if (this.state.projectScreen.selectedVersionModel !== null) {
              this.modelApi
                .apiGrexmanagerModelList({
                  versionModelId: this.state.projectScreen.selectedVersionModel,
                })
                .then((modelsList) => {
                  this.state.projectScreen.modelsList = modelsList;
                  this.state.projectScreen.loadingModels = false;
                  callback(this.state);
                })
                .catch((error) => {
                  // TODO: Handle error
                  console.log(error);

                  this.state.projectScreen.errorMessage =
                    "Rekenmodellen konden niet geladen worden.";
                  callback(this.state);
                  setTimeout(() => {
                    this.state.projectScreen.errorMessage = "";
                    callback(this.state);
                  }, 5000);
                });
            }
          })
          .catch((error) => {
            // TODO: Handle error
            console.log(error);

            this.state.projectScreen.errorMessage =
              "Versies konden niet geladen worden.";
            callback(this.state);
            setTimeout(() => {
              this.state.projectScreen.errorMessage = "";
              callback(this.state);
            }, 5000);
          });
      }
    }
  }

  onVersionModelClick(
    this: AppStateHandler,
    versionId: number,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.projectScreen.selectedVersionModel !== versionId) {
      // Check if version exists
      const versionIndex = this.state.projectScreen.versionsList.findIndex(
        (object) => {
          return object.id === versionId;
        }
      );
      if (versionIndex > -1) {
        this.state.projectScreen.selectedVersionModel = versionId;
        // this.state.modelsList = [];
        this.state.projectScreen.loadingModels = true;
        callback(this.state);

        // Get Models
        if (this.state.projectScreen.selectedVersionModel !== null) {
          this.modelApi
            .apiGrexmanagerModelList({
              versionModelId: this.state.projectScreen.selectedVersionModel,
            })
            .then((modelsList) => {
              this.state.projectScreen.modelsList = modelsList;
              this.state.projectScreen.loadingModels = false;
              callback(this.state);
            })
            .catch((error) => {
              // TODO: Handle error
              console.log(error);

              this.state.projectScreen.errorMessage =
                "Rekenmodellen konden niet geladen worden";
              callback(this.state);
              setTimeout(() => {
                this.state.projectScreen.errorMessage = "";
                callback(this.state);
              }, 5000);
            });
        }
      }
    }
  }

  onModelClick(
    this: AppStateHandler,
    ModelId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.openModel(ModelId, callback);
  }

  openModel(
    this: AppStateHandler,
    ModelId: number,
    callback: (newState: AppStateType) => void,
    force: boolean = false
  ) {
    console.log("Open rekenmodel");
    this.state.popUpState = PopUpState.OpenModelLoading;
    this.state.projectScreen.modelToOpen = ModelId;
    callback(this.state);
    this.modelApi
      .apiGrexmanagerModelOpenRetrieve({
        id: ModelId,
        force: force,
      })
      .then((response) => {
        const link = document.createElement("a");
        link.href = `ms-excel:ofv|u|${this.basePath()}/api/grexfileserver/file/${
          response.uuid
        }/${response.filenameOrig}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error.response);
        if (error.response.status === 409) {
          console.log("Model already open");
          this.state.popUpState = PopUpState.ModelAlreadyOpenAlert;
          callback(this.state);
        } else {
          this.state.projectScreen.errorMessage =
            "Fout bij het openen van het rekenmodel.";
          callback(this.state);
          setTimeout(() => {
            this.state.projectScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        }
      });
  }

  /* Project submenu */
  onProjectMoreInfoClick(
    this: AppStateHandler,
    projectId: number,
    callback: (newState: AppStateType) => void
  ) {
    const projectIndex = this.state.projectScreen.projectsList.findIndex(
      (object) => {
        return object.id === projectId;
      }
    );
    if (projectIndex > -1) {
      this.loadProjectInfoScreen(projectId, callback);
      this.closeProjectScreenSubMenu(callback);
    }
  }

  onProjectDuplicateClick(
    this: AppStateHandler,
    projectId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.projectApi
      .apiGrexmanagerProjectDuplicateRetrieve({
        id: projectId,
      })
      .then((_) => {
        this.projectScreenReloadProjects(callback);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectScreen.errorMessage =
          "Fout bij het dupliceren van het project.";
        callback(this.state);
        setTimeout(() => {
          this.state.projectScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
    this.closeProjectScreenSubMenu(callback);
  }

  onProjectDeleteClick(
    this: AppStateHandler,
    projectId: number,
    callback: (newState: AppStateType) => void
  ) {
    console.log(`${projectId} Delete`);
    this.state.projectScreen.projectToDelete = projectId;
    this.state.popUpState = PopUpState.DeleteProjectAlert;
    callback(this.state);
    this.closeProjectScreenSubMenu(callback);
  }

  permanentlyDeleteProject(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.popUpState = PopUpState.Hidden;
    callback(this.state);
    this.projectApi
      .apiGrexmanagerProjectDestroy({
        id: this.state.projectScreen.projectToDelete as number,
      })
      .then((_) => {
        this.state.projectScreen.selectedProject = null;
        callback(this.state);
        this.projectScreenReloadProjects(callback);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectScreen.errorMessage =
          "Fout bij het verwijderen van het project.";
        callback(this.state);
        setTimeout(() => {
          this.state.projectScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
    this.closeProjectScreenSubMenu(callback);
  }

  /* VersionModel submenu */
  onVersionModelMoreInfoClick(
    this: AppStateHandler,
    versionId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.loadVersionModelInfoScreen(versionId, callback);
    this.closeProjectScreenSubMenu(callback);
  }

  onVersionModelDuplicateClick(
    this: AppStateHandler,
    versionId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.versionModelApi
      .apiGrexmanagerVersionModelDuplicateRetrieve({
        id: versionId,
      })
      .then((_) => {
        this.projectScreenReloadVersionModels(callback);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectScreen.errorMessage =
          "Fout bij het dupliceren van de versie";
        callback(this.state);
        setTimeout(() => {
          this.state.projectScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
    this.closeProjectScreenSubMenu(callback);
  }

  onVersionModelDeleteClick(
    this: AppStateHandler,
    versionId: number,
    callback: (newState: AppStateType) => void
  ) {
    console.log(`${versionId} Delete`);
    this.state.projectScreen.versionModelToDelete = versionId;
    this.state.popUpState = PopUpState.DeleteVersionModelAlert;
    callback(this.state);
    this.closeProjectScreenSubMenu(callback);
  }

  permanentlyDeleteVersionModel(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.popUpState = PopUpState.Hidden;
    callback(this.state);
    this.versionModelApi
      .apiGrexmanagerVersionModelDestroy({
        id: this.state.projectScreen.versionModelToDelete as number,
      })
      .then((_) => {
        this.state.projectScreen.selectedVersionModel = null;
        callback(this.state);
        this.projectScreenReloadVersionModels(callback);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectScreen.errorMessage =
          "Fout bij het verwijderen van de versie.";
        callback(this.state);
        setTimeout(() => {
          this.state.projectScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
    this.closeProjectScreenSubMenu(callback);
  }

  onVersionModelAddAttachmentClick(
    this: AppStateHandler,
    versionId: number,
    callback: (newState: AppStateType) => void
  ) {
    console.log(`${versionId} Add Attachment`);
    this.closeProjectScreenSubMenu(callback);
  }

  /* Calc model submenu */
  onModelMoreInfoClick(
    this: AppStateHandler,
    ModelId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.loadModelInfoScreen(ModelId, callback);
    this.closeProjectScreenSubMenu(callback);
  }

  onModelDuplicateClick(
    this: AppStateHandler,
    ModelId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.modelApi
      .apiGrexmanagerModelDuplicateRetrieve({
        id: ModelId,
      })
      .then((_) => {
        this.projectScreenReloadModels(callback);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectScreen.errorMessage =
          "Fout bij het dupliceren van het rekenmodel.";
        callback(this.state);
        setTimeout(() => {
          this.state.projectScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
    this.closeProjectScreenSubMenu(callback);
  }

  onModelDeleteClick(
    this: AppStateHandler,
    modelId: number,
    callback: (newState: AppStateType) => void
  ) {
    console.log(`${modelId} Delete`);
    this.state.projectScreen.modelToDelete = modelId;
    this.state.popUpState = PopUpState.DeleteModelAlert;
    callback(this.state);
    this.closeProjectScreenSubMenu(callback);
  }

  onModelCancelSessionEditClick(
    this: AppStateHandler,
    modelId: number,
    callback: (newState: AppStateType) => void
  ) {
    console.log(`${modelId} Cancel edit session`);
    this.state.projectScreen.modelToCancelSessionEdit = modelId;

    this.modelApi
      .apiGrexmanagerModelSessionEditRetrieve({
        id: modelId,
      })
      .then((sessioninfo) => {
        this.state.projectScreen.userEmailModelToCancelEditSession =
          sessioninfo.email;
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.projectScreen.errorMessage =
          "Fout bij het ophalen van info over de modelsessie";
        callback(this.state);
        setTimeout(() => {
          this.state.projectScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });

    this.state.popUpState = PopUpState.CancelModelSessionEditAlert;
    callback(this.state);
    this.closeProjectScreenSubMenu(callback);
  }

  permanentlyCancelModelSessionEdit(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.popUpState = PopUpState.Hidden;
    callback(this.state);

    if (this.state.projectScreen.modelToCancelSessionEdit) {
      this.modelApi
        .apiGrexmanagerModelSessionEditDestroy({
          id: this.state.projectScreen.modelToCancelSessionEdit,
        })
        .then((_) => {
          this.projectScreenReloadModels(callback);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.projectScreen.errorMessage =
            "Fout bij het beëindigen van de modelsessie";
          callback(this.state);
          setTimeout(() => {
            this.state.projectScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
      this.closeProjectScreenSubMenu(callback);
    }
  }

  permanentlyDeleteModel(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.popUpState = PopUpState.Hidden;
    callback(this.state);

    if (this.state.projectScreen.modelToDelete) {
      this.modelApi
        .apiGrexmanagerModelDestroy({
          id: this.state.projectScreen.modelToDelete,
        })
        .then((_) => {
          this.projectScreenReloadModels(callback);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.projectScreen.errorMessage =
            "Fout bij het verwijderen van het rekenmodel.";
          callback(this.state);
          setTimeout(() => {
            this.state.projectScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
      this.closeProjectScreenSubMenu(callback);
    }
  }
  openProjectScreenSubMenu(
    this: AppStateHandler,
    projectId: number | null,
    versionId: number | null,
    ModelId: number | null,
    mouseX: number,
    mouseY: number,
    windowHeight: number,
    windowWidth: number,
    callback: (newState: AppStateType) => void
  ) {
    if (projectId !== null || versionId !== null || ModelId !== null) {
      const margin = 20; /* from the edge of the screen */
      const pupUpWidth = 190; /* same as width in ProjectScreen.css > #ProjectScreen-ProjectSubMenu */
      const menuWidth = 72; /* same as width in MenuBar.css > #MenuBar-Main */
      const mouseOffset = 10; /* to make sure the mouse is always in the popup */
      let pupUpHeight = 0;

      if (projectId !== null) {
        this.state.projectScreen.projectPopUpProjectId = projectId;
        pupUpHeight = 122; /* (3 * 30px(height)) + (4 * 6px(margin)) + (2 * 2px(padding)) + (2 * 2px(border)) */
      } else if (versionId !== null) {
        this.state.projectScreen.projectPopUpVersionModelId = versionId;
        pupUpHeight = 194; /* (5 * 30px(height)) + (6 * 6px(margin)) + (2 * 2px(padding)) + (2 * 2px(border)) */
      } else if (ModelId !== null) {
        this.state.projectScreen.projectPopUpModelId = ModelId;
        pupUpHeight = 194; /* (5 * 30px(height)) + (6 * 6px(margin)) + (2 * 2px(padding)) + (2 * 2px(border)) */
      }

      let popUpPositionX = mouseX - menuWidth - mouseOffset;
      let popUpPositionY = mouseY - mouseOffset;
      if (mouseX - mouseOffset + pupUpWidth > windowWidth - margin) {
        popUpPositionX = windowWidth - margin - pupUpWidth - menuWidth;
      }
      if (mouseY - mouseOffset + pupUpHeight > windowHeight - margin) {
        popUpPositionY = windowHeight - margin - pupUpHeight;
      }

      this.state.projectScreen.popUpPosition = {
        x: popUpPositionX,
        y: popUpPositionY,
      };
      callback(this.state);
    }
  }

  closeProjectScreenSubMenu(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.projectScreen.projectPopUpProjectId = null;
    this.state.projectScreen.projectPopUpVersionModelId = null;
    this.state.projectScreen.projectPopUpModelId = null;
    callback(this.state);
  }

  // Move
  onVersionModelMoveClick(
    this: AppStateHandler,
    versionId: number,
    callback: (newState: AppStateType) => void
  ) {
    console.log(`${versionId} Move`);
    this.state.projectScreen.versionModelToMove = versionId;
    this.state.projectScreen.moveOverviewState =
      MoveOverviewState.MoveVersionModel;
    callback(this.state);
    this.closeProjectScreenSubMenu(callback);
  }

  onModelMoveClick(
    this: AppStateHandler,
    modelId: number,
    callback: (newState: AppStateType) => void
  ) {
    console.log(`${modelId} Move`);
    this.state.projectScreen.modelToMove = modelId;
    this.state.projectScreen.moveOverviewState = MoveOverviewState.MoveModel;
    callback(this.state);
    this.closeProjectScreenSubMenu(callback);
  }

  onMoveProjectClick(
    this: AppStateHandler,
    projectId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.projectScreen.selectedMoveTargetProject = projectId;
    if (
      this.state.projectScreen.moveOverviewState === MoveOverviewState.MoveModel
    ) {
      this.versionModelApi
        .apiGrexmanagerVersionModelList({
          projectId: this.state.projectScreen.selectedMoveTargetProject,
        })
        .then((versionModelList) => {
          this.state.projectScreen.moveVersionModelList = versionModelList;
          callback(this.state);
        })
        .catch((error) => {
          console.log(error);
          this.state.projectScreen.errorMessage =
            "Versies konden niet geladen worden.";
          callback(this.state);
          setTimeout(() => {
            this.state.projectScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
    }
    callback(this.state);
  }

  onMoveVersionModelClick(
    this: AppStateHandler,
    versionModelId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.projectScreen.selectedMoveTargetVersionModel = versionModelId;
    callback(this.state);
  }

  moveVersionModelBack(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.projectScreen.selectedMoveTargetProject = null;
    this.state.projectScreen.selectedVersionModel = null;
    this.state.projectScreen.moveVersionModelList = [];
    callback(this.state);
  }

  moveVersionModel(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.projectScreen.versionModelToMove !== null &&
      this.state.projectScreen.selectedMoveTargetProject !== null
    ) {
      this.versionModelApi
        .apiGrexmanagerVersionModelMoveCreate({
          id: this.state.projectScreen.versionModelToMove,
          versionModelMoveRequest: {
            toProject: this.state.projectScreen.selectedMoveTargetProject,
          },
        })
        .then((_) => {
          this.projectScreenReloadVersionModels(callback);
          this.closeMoveDialog(callback);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.projectScreen.errorMessage =
            "Fout bij het verplaatsen van de versie.";
          callback(this.state);
          setTimeout(() => {
            this.state.projectScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  moveModel(this: AppStateHandler, callback: (newState: AppStateType) => void) {
    if (
      this.state.projectScreen.modelToMove !== null &&
      this.state.projectScreen.selectedMoveTargetProject !== null &&
      this.state.projectScreen.selectedMoveTargetVersionModel !== null
    ) {
      this.modelApi
        .apiGrexmanagerModelMoveCreate({
          id: this.state.projectScreen.modelToMove,
          modelMoveRequest: {
            toVersion: this.state.projectScreen.selectedMoveTargetVersionModel,
          },
        })
        .then((_) => {
          this.projectScreenReloadModels(callback);
          this.closeMoveDialog(callback);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.projectScreen.errorMessage =
            "Fout bij het verplaatsen van het rekenmodel.";
          callback(this.state);
          setTimeout(() => {
            this.state.projectScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  closeMoveDialog(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.projectScreen.versionModelToMove = null;
    this.state.projectScreen.modelToMove = null;
    this.state.projectScreen.moveOverviewState = MoveOverviewState.Hidden;
    this.state.projectScreen.selectedMoveTargetProject = null;
    this.state.projectScreen.selectedMoveTargetVersionModel = null;
    this.state.projectScreen.moveVersionModelList = [];
    callback(this.state);
  }
}
