import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import Button from "@shared/components/Button";
import CheckBox from "@shared/components/CheckBox";
import DropDown, { DropDownListItem } from "@shared/components/DropDown";
import SectionTitle from "@shared/components/SectionTitle";
import { isModelSelectedIn } from "@shared/utils/helperFunctions";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import Section from "../../components/Section";
import {
  IndexToProjectSortOption,
  ProjectSortOptionToDropDown,
  ProjectSortOptionToIndex,
} from "../../helperFunctions";
import "./ReportScreen.css";

interface ReportScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ReportScreen extends React.Component<ReportScreenProps> {
  render() {
    return (
      <div id="ReportScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Rapportages",
            },
          ]}
        ></BreadCrumbs>

        {(() => {
          if (this.props.state.reportScreen.errorMessage !== "") {
            return (
              <Alert
                Error={true}
                Message={this.props.state.reportScreen.errorMessage}
              ></Alert>
            );
          }
        })()}

        <div id="ReportScreen-FilterSortHolder">
          <Section id="ReportScreen-FilterSection">
            <DropDown
              list={this.props.state.reportScreen.typeProject}
              onChange={(newKey) => {
                this.props.stateHandler.reportScreenOnProjectFilterChange(
                  { typeProject: newKey },
                  this.props.updateStateCallback
                );
              }}
              selectedKey={this.props.state.reportScreen.filterTypeProject}
            ></DropDown>
            <DropDown
              list={this.props.state.reportScreen.subregions}
              onChange={(newKey) => {
                this.props.stateHandler.reportScreenOnProjectFilterChange(
                  { subregion: newKey },
                  this.props.updateStateCallback
                );
              }}
              selectedKey={this.props.state.reportScreen.filterSubregion}
            ></DropDown>
          </Section>

          <Section>
            <DropDown
              list={ProjectSortOptionToDropDown()}
              onChange={(newKey) => {
                this.props.stateHandler.reportScreenUpdateProjectSorting(
                  IndexToProjectSortOption(newKey),
                  this.props.updateStateCallback
                );
              }}
              selectedKey={ProjectSortOptionToIndex(
                this.props.state.reportScreen.projectSorting
              )}
            ></DropDown>
          </Section>
        </div>
        <div id="ReportScreen-SelectModelSection">
          <div id="ReportScreen-ScrollView">
            <div id="ReportScreen-ScrollHolder">
              <div className="ReportScreen-Section">
                <div className="ReportScreen-SectionHeader">
                  <div className="ReportScreen-SectionHeaderTitle">
                    {this.props.state.reportScreen.loadingSpinner ? (
                      <div className="spinner"></div>
                    ) : (
                      <CheckBox
                        checked={
                          this.props.state.reportScreen.allProjectsSelected
                        }
                        onClick={(checked) => {
                          this.props.stateHandler.toggleSelectAllProjectsButton(
                            this.props.updateStateCallback
                          );
                        }}
                      ></CheckBox>
                    )}
                    Projecten
                  </div>
                </div>
                <div className="ReportScreen-SectionList">
                  {this.props.state.reportScreen.projectsList.map((item) => {
                    if (item.visible) {
                      return (
                        <div
                          key={item.id}
                          className={
                            item.id ===
                            this.props.state.reportScreen.selectedProject
                              ? "ReportScreen-ListItem ReportScreen-ListItemSelected"
                              : "ReportScreen-ListItem"
                          }
                          onClick={() => {
                            this.props.stateHandler.reportScreenOnProjectClick(
                              item.id,
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          <CheckBox
                            checked={isModelSelectedIn(
                              this.props.state.reportScreen.selectedModels,
                              { project: item.id }
                            )}
                            onClick={(checked) => {
                              this.props.stateHandler.reportScreenToggleModel(
                                { project: item.id },
                                this.props.updateStateCallback
                              );
                            }}
                          ></CheckBox>
                          <span>{item.name}</span>
                          <span className="ReportScreen-SubText">
                            {item.number ? " - " + item.number : ""}
                          </span>
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </div>
                <div
                  className={
                    this.props.state.reportScreen.loadingProjects
                      ? "ReportScreen-SectionDisabledOverlay disabled"
                      : "ReportScreen-SectionDisabledOverlay"
                  }
                ></div>
              </div>

              <div className="ReportScreen-Divider"></div>

              <div className="ReportScreen-Section">
                <div className="ReportScreen-SectionHeader">
                  <div className="ReportScreen-SectionHeaderTitle">Versies</div>
                </div>
                <div className="ReportScreen-SectionList">
                  {this.props.state.reportScreen.versionsList.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          item.id ===
                          this.props.state.reportScreen.selectedVersionModel
                            ? "ReportScreen-ListItem ReportScreen-ListItemSelected"
                            : "ReportScreen-ListItem"
                        }
                        onClick={() => {
                          this.props.stateHandler.reportScreenOnVersionModelClick(
                            item.id,
                            this.props.updateStateCallback
                          );
                        }}
                      >
                        <CheckBox
                          checked={isModelSelectedIn(
                            this.props.state.reportScreen.selectedModels,
                            { versionModel: item.id }
                          )}
                          onClick={(checked) => {
                            this.props.stateHandler.reportScreenToggleModel(
                              { versionModel: item.id },
                              this.props.updateStateCallback
                            );
                          }}
                        ></CheckBox>
                        <span>{item.name}</span>
                      </div>
                    );
                  })}
                </div>
                <div
                  className={
                    this.props.state.reportScreen.loadingVersionModels
                      ? "ReportScreen-SectionDisabledOverlay disabled"
                      : "ReportScreen-SectionDisabledOverlay"
                  }
                ></div>
              </div>

              <div className="ReportScreen-Divider"></div>

              <div className="ReportScreen-Section">
                <div className="ReportScreen-SectionHeader">
                  <div className="ReportScreen-SectionHeaderTitle">
                    Rekenmodellen
                  </div>
                </div>
                <div className="ReportScreen-SectionList">
                  {this.props.state.reportScreen.modelsList.map((item) => {
                    return (
                      <div key={item.id} className={"ReportScreen-ListItem"}>
                        <CheckBox
                          checked={isModelSelectedIn(
                            this.props.state.reportScreen.selectedModels,
                            { model: item.id }
                          )}
                          onClick={(checked) => {
                            this.props.stateHandler.reportScreenToggleModel(
                              { model: item.id },
                              this.props.updateStateCallback
                            );
                          }}
                        ></CheckBox>
                        <span>{item.name}</span>
                        <span className="ReportScreen-SubText">
                          {" "}
                          - {item.typeModelName}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div
                  className={
                    this.props.state.reportScreen.loadingModels
                      ? "ReportScreen-SectionDisabledOverlay disabled"
                      : "ReportScreen-SectionDisabledOverlay"
                  }
                ></div>
              </div>
            </div>
          </div>
          <div className="ReportScreen-HorizontalDivider"></div>

          <div id="ReportScreen-SelectedText">
            {this.props.state.reportScreen.selectedModels.length === 1
              ? "1 rekenmodel geselecteerd. "
              : `${this.props.state.reportScreen.selectedModels.length} rekenmodellen geselecteerd. `}
          </div>
        </div>

        <div className="ReportScreen-ActionRow">
          <div className="ReportScreen-ActionSection">
            <SectionTitle subtitle={true}>Rapportages in Excel</SectionTitle>
            <DropDown
              list={((): DropDownListItem[] => {
                return this.props.state.reportScreen.templateReports.map(
                  (x) => {
                    return {
                      key: x.id,
                      name: x.name,
                      disabled: false,
                    };
                  }
                );
              })()}
              onChange={(newKey) => {
                this.props.stateHandler.reportScreenSelectTemplate(
                  newKey,
                  this.props.updateStateCallback
                );
              }}
              selectedKey={this.props.state.reportScreen.selectedTemplateReport}
            ></DropDown>

            <Button
              onClick={() => {
                this.props.stateHandler.reportScreenMakeReport(
                  this.props.updateStateCallback
                );
              }}
              disabled={
                !this.props.state.reportScreen.correctSelectedModelAmount
              }
            >
              Maak rapportage
            </Button>

            <span id="ReportScreen-ErrorMessage">
              {this.props.state.reportScreen.formError}
            </span>
          </div>
          <div className="ReportScreen-Spacer"></div>
          <div className="ReportScreen-ActionSection">
            <SectionTitle subtitle={true}>BI Rapportages</SectionTitle>

            <Button
              onClick={() => {
                this.props.stateHandler.changeScreen(
                  ScreenState.BiLinks,
                  this.props.updateStateCallback
                );
              }}
            >
              Bekijk gegenereerde links
            </Button>
            <Button
              onClick={() => {
                this.props.stateHandler.changeScreen(
                  ScreenState.NewBiLink,
                  this.props.updateStateCallback
                );
              }}
              disabled={
                this.props.state.reportScreen.selectedModels.length === 0
              }
            >
              Genereer link met selectie
            </Button>
          </div>
        </div>
        {/* <div id="ReportScreen-AnalysisHolder"> */}
      </div>
    );
  }
}
