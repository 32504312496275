import Alert from "@shared/components/Alert";
import Button from "@shared/components/Button";
import ButtonList from "@shared/components/ButtonList";
import GrexManagerLogo from "@shared/components/GrexManagerLogo";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, LoginState } from "../../Types";
import "./ResetPasswordCodeScreen.css";
interface ResetPasswordNewPasswordScreenProps {
  state: AppStateType;
  loginstate: LoginState;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ResetPasswordNewPasswordScreen extends React.Component<ResetPasswordNewPasswordScreenProps> {
  render() {
    return (
      <div id="ResetPasswordCodeScreen-Container">
        <div id="ResetPasswordCodeScreen-Section">
          <GrexManagerLogo />

          <div id="ResetPasswordCodeScreen-LoginMfaHolder">
            <SectionTitle>Stel uw wachtwoord opnieuw in</SectionTitle>

            {(() => {
              if (this.props.state.ResetPassword.newPasswordinfo !== "") {
                return (
                  <>
                    <Alert
                      Error={false}
                      Message={this.props.state.ResetPassword.newPasswordinfo}
                    ></Alert>

                    <Button
                      onClick={() => {
                        this.props.stateHandler.changeLoginScreen(
                          LoginState.LoginEmailPassword,
                          this.props.updateStateCallback
                        );
                      }}
                    >
                      Terug naar inloggen
                    </Button>
                  </>
                );
              } else if (
                this.props.state.ResetPassword.newPasswordinfo === ""
              ) {
                return (
                  <>
                    {(() => {
                      if (
                        this.props.state.ResetPassword.newPasswordError !== ""
                      ) {
                        return (
                          <>
                            <Alert
                              Error={true}
                              Message={
                                this.props.state.ResetPassword.newPasswordError
                              }
                            ></Alert>
                          </>
                        );
                      }
                    })()}
                    <div className="label">Nieuw Wachtwoord</div>
                    <ul>
                      {this.props.state.PasswordPolicy.notAppliedRequirements.map(
                        (requirement, index) => (
                          <li key={index}>{requirement}</li>
                        )
                      )}
                    </ul>
                    <TextInput
                      type={TextInputType.NewPassword}
                      placeholder="Nieuw Wachtwoord"
                      value={this.props.state.ResetPassword.newPassword}
                      onChange={(newPassword: string) => {
                        this.props.stateHandler.ResetPasswordNewPasswordForm(
                          { newPassword: newPassword },
                          this.props.updateStateCallback
                        );
                      }}
                    ></TextInput>
                    <div className="label">Bevestig wachtwoord</div>
                    <TextInput
                      type={TextInputType.NewPassword}
                      placeholder="Bevestig wachtwoord"
                      value={this.props.state.ResetPassword.newPasswordRepeat}
                      onChange={(newPasswordRepeat: string) => {
                        this.props.stateHandler.ResetPasswordNewPasswordForm(
                          { newPasswordRepeat: newPasswordRepeat },
                          this.props.updateStateCallback
                        );
                      }}
                    ></TextInput>

                    <div className="ResetPasswordCodeScreen-buttonbox">
                      <ButtonList
                        data={[
                          {
                            title: "Opslaan",
                            callback: () => {
                              this.props.stateHandler.ResetPasswordSetNewPassword(
                                this.props.updateStateCallback
                              );
                            },
                          },
                          {
                            title: "Annuleren",
                            callback: () => {
                              this.props.stateHandler.changeLoginScreen(
                                LoginState.LoginEmailPassword,
                                this.props.updateStateCallback
                              );
                            },
                          },
                        ]}
                      ></ButtonList>
                    </div>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>
    );
  }
}
