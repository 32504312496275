import { RoleEnum, StateEnum, TypeEnum } from "../client/lib";
import { DropDownListItem } from "../components/DropDown";

export const formatDate = (date: Date): string => {
  const monthMap: string[] = [
    "jan",
    "feb",
    "mar",
    "apr",
    "mei",
    "jun",
    "jul",
    "aug",
    "sep",
    "okt",
    "nov",
    "dec",
  ];

  let day: string = date.getDate().toString();
  if (day.length === 1) {
    day = "0" + day;
  }
  const month: string = monthMap[date.getMonth()];
  const year: number = date.getFullYear();
  let hours: string = date.getHours().toString();
  if (hours.length === 1) {
    hours = "0" + hours;
  }
  let minutes: string = date.getMinutes().toString();
  if (minutes.length === 1) {
    minutes = "0" + minutes;
  }

  const dateString: string =
    day + " " + month + " " + year + ", " + hours + ":" + minutes;
  return dateString;
};

export const bytesToString = (bytes: number): string => {
  if (bytes >= 1000000000) {
    return `${Math.round(bytes / 1000000000)} GB`;
  } else if (bytes >= 1000000) {
    return `${Math.round(bytes / 1000000)} MB`;
  } else if (bytes >= 1000) {
    return `${Math.round(bytes / 1000)} KB`;
  } else {
    return `${Math.round(bytes)} Bytes`;
  }
};

// Functions to convert RoleEnum enum to DropDownListItems
export const RoleEnumToDropDown = (): DropDownListItem[] => {
  // This function is meant for global user roles.
  return (Object.keys(RoleEnum) as Array<keyof typeof RoleEnum>).map(
    (key, index) => {
      let displayName = "???";
      switch (RoleEnum[key]) {
        case RoleEnum.Adm:
          displayName = "Beheerder";
          break;
        case RoleEnum.Non:
          displayName = "Geen";
          break;
        case RoleEnum.Usr:
          displayName = "Gebruiker";
          break;
        case RoleEnum.Vwr:
          displayName = "Lezer";
          break;
        default:
          break;
      }
      return {
        key: index,
        name: displayName,
        disabled: false,
      };
    }
  );
};
export const RoleProjectEnumToDropDown = (): DropDownListItem[] => {
  // This function is meant for user project roles (a.k.a. permissions/rechten).
  return (Object.keys(RoleEnum) as Array<keyof typeof RoleEnum>).map(
    (key, index) => {
      let displayName = "???";
      switch (RoleEnum[key]) {
        case RoleEnum.Adm:
          displayName = "Beheren";
          break;
        case RoleEnum.Non:
          displayName = "Geen";
          break;
        case RoleEnum.Usr:
          displayName = "Bewerken";
          break;
        case RoleEnum.Vwr:
          displayName = "Lezen";
          break;
        default:
          break;
      }
      return {
        key: index,
        name: displayName,
        disabled: false,
      };
    }
  );
};
export const RoleEnumToIndex = (role: RoleEnum): number => {
  return Object.values(RoleEnum).indexOf(role);
};
export const IndexToRoleEnum = (roleIndex: number): RoleEnum => {
  const key = Object.keys(RoleEnum)[roleIndex] as keyof typeof RoleEnum;
  return RoleEnum[key];
};

// Functions to convert StateEnum enum to DropDownListItems
export const StateEnumToDropDown = (): DropDownListItem[] => {
  return (Object.keys(StateEnum) as Array<keyof typeof StateEnum>).map(
    (key, index) => {
      let displayName = "???";
      switch (StateEnum[key]) {
        case StateEnum.ActAuth:
          displayName = "Actief (autorisatie nodig)";
          break;
        case StateEnum.ActFree:
          displayName = "Actief (vrij toegankelijk)";
          break;
        case StateEnum.Inactive:
          displayName = "Inactief";
          break;
        default:
          break;
      }
      return {
        key: index,
        name: displayName,
        disabled: false,
      };
    }
  );
};
export const StateEnumToIndex = (state: StateEnum): number => {
  return Object.values(StateEnum).indexOf(state);
};
export const IndexToStateEnum = (roleIndex: number): StateEnum => {
  const key = Object.keys(StateEnum)[roleIndex] as keyof typeof StateEnum;
  return StateEnum[key];
};

// Functions to convert TypeEnum enum to DropDownListItems
export const AttachmentTypeToDropDown = (): DropDownListItem[] => {
  return (Object.keys(TypeEnum) as Array<keyof typeof TypeEnum>).map(
    (key, index) => {
      let displayName = "???";
      switch (TypeEnum[key]) {
        case TypeEnum.File:
          displayName = "File";
          break;
        case TypeEnum.Link:
          displayName = "Link";
          break;
        default:
          break;
      }
      return {
        key: index,
        name: displayName,
        disabled: false,
      };
    }
  );
};
export const AttachmentTypeToIndex = (type: TypeEnum): number => {
  return Object.values(TypeEnum).indexOf(type);
};
export const IndexToAttachmentType = (typeIndex: number): TypeEnum => {
  const key = Object.keys(TypeEnum)[typeIndex] as keyof typeof TypeEnum;
  return TypeEnum[key];
};

//
export const isModelSelectedIn = (
  selectedModels: {
    model: number;
    versionModel: number;
    project: number;
  }[],
  lookFor: {
    model?: number;
    versionModel?: number;
    project?: number;
  }
): boolean => {
  if (lookFor.model !== undefined) {
    return (
      selectedModels.findIndex((object) => {
        return object.model === lookFor.model;
      }) > -1
    );
  } else if (lookFor.versionModel !== undefined) {
    return (
      selectedModels.findIndex((object) => {
        return object.versionModel === lookFor.versionModel;
      }) > -1
    );
  } else if (lookFor.project !== undefined) {
    return (
      selectedModels.findIndex((object) => {
        return object.project === lookFor.project;
      }) > -1
    );
  } else {
    return false;
  }
};

export const dropDownListPrintItem = (
  list: DropDownListItem[],
  key: number
): string => {
  if (list.length > 0) {
    let item = list.find((object) => {
      return object.key === key;
    });
    if (item) {
      return item.name;
    } else {
      return "Aan het laden...";
    }
  } else {
    return "Aan het laden...";
  }
};

export const isEmailValid = (email: any): boolean => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the email matches the pattern
  return emailPattern.test(email);
};

export const convertToDutchDateTime = (dateTimeString: any): string => {
  const dateTime = new Date(dateTimeString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "Europe/Amsterdam", // Set the desired time zone
  };
  try {
    const formatter = new Intl.DateTimeFormat("nl-NL", options);
    return formatter.format(dateTime);
  } catch (error) {
    return "-";
  }
};

export const extendUserRole = (role: any): string => {
  const roles: { [key: string]: string } = {
    ADM: "Beheerder",
    EXT: "Externe",
    NON: "Geen",
    USR: "Gebruiker",
    VWR: "Lezer",
  };
  return roles[role];
};

export const formatDateInput = (date: Date): string => {
  const year: number = date.getFullYear();
  const month: string = String(date.getMonth() + 1).padStart(2, "0");
  const day: string = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
