import { RoleEnum } from "@shared/client/lib";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class ModelInfoStateHandler {
  loadModelInfoScreen(
    this: AppStateHandler,
    modelId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.changeScreen(ScreenState.Projects_ModelInfo, callback);
    this.state.modelInfoScreen = {
      loadingForm: true,
      formError: "",
      modelId: null,
      modelName: "",
      description: "",
      modelTypes: [
        {
          key: 0,
          name: "Aan het laden...",
          disabled: true,
        },
      ],
      modelType: 0,
      frozen: false,
      author: "",
      lastEdited: null,
      lastModifiedByNameFull: "",
      mutations: null,
      projectRole: null,
      modelHistoryList: [],
      newHistoryFile: null,
      fileUploadSuccessMessage: "",
      fileUploadErrorMessage: "",
    };
    callback(this.state);

    this.templateModelApi
      .apiGrexmanagerTemplateModelList()
      .then((templateModels) => {
        this.state.modelInfoScreen.modelTypes = templateModels.map(
          (templateModel) => {
            return {
              key: templateModel.id,
              name: templateModel.name,
              disabled: false,
            };
          }
        );
        callback(this.state);

        this.modelApi
          .apiGrexmanagerModelRetrieve({
            id: modelId,
          })
          .then((model) => {
            this.state.modelInfoScreen.loadingForm = false;
            this.state.modelInfoScreen.modelId = model.id;
            this.state.modelInfoScreen.modelName = model.name;
            this.state.modelInfoScreen.description = model.description || "";
            this.state.modelInfoScreen.modelTypes =
              this.state.modelInfoScreen.modelTypes;
            this.state.modelInfoScreen.modelType = model.typeModel;
            this.state.modelInfoScreen.frozen = model.frozen;
            this.state.modelInfoScreen.author = model.authorNameFull;
            this.state.modelInfoScreen.lastEdited = model.timestampLastModified;
            this.state.modelInfoScreen.lastModifiedByNameFull =
              model.lastModifiedByNameFull;
            this.state.modelInfoScreen.mutations = model.mutations;
            this.state.modelInfoScreen.projectRole = model.projectRole;
            callback(this.state);
            this.loadModelHistory(callback);
          })
          .catch((error) => {
            console.log(error);
            this.state.modelInfoScreen.formError =
              "Info over model kon niet geladen worden.";
            callback(this.state);
            setTimeout(() => {
              this.state.modelInfoScreen.formError = "";
              callback(this.state);
            }, 5000);
          });
      })
      .catch((error) => {
        console.log(error);
        this.state.modelInfoScreen.formError =
          "Lijst met sjablonen kon niet geladen worden.";
        callback(this.state);
        setTimeout(() => {
          this.state.modelInfoScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  modelInfoUpdateForm(
    this: AppStateHandler,
    newFormValues: {
      modelName?: string;
      description?: string;
      modelType?: number;
      frozen?: boolean;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.modelName !== undefined) {
      this.state.modelInfoScreen.modelName = newFormValues.modelName;
    }
    if (newFormValues.description !== undefined) {
      this.state.modelInfoScreen.description = newFormValues.description;
    }
    if (newFormValues.modelType !== undefined) {
      this.state.modelInfoScreen.modelType = newFormValues.modelType;
    }
    if (newFormValues.frozen !== undefined) {
      this.state.modelInfoScreen.frozen = newFormValues.frozen;
    }
    callback(this.state);
  }

  modelInfoChangeMutationName(
    this: AppStateHandler,
    mutationId: number,
    newMutationName: string,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.modelInfoScreen.mutations !== null) {
      const mutationObjectIndex =
        this.state.modelInfoScreen.mutations.findIndex((object) => {
          return object.id === mutationId;
        }) || -1;
      if (mutationObjectIndex > -1) {
        this.state.modelInfoScreen.mutations[mutationObjectIndex].name =
          newMutationName;
        callback(this.state);
      }
    }
  }

  modelInfoSave(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.modelInfoScreen.modelName === "" ||
      this.state.modelInfoScreen.modelType < 0
    ) {
      this.state.modelInfoScreen.formError =
        "Vul alle velden in die gemarkeerd zijn met een *";
      callback(this.state);
      setTimeout(() => {
        this.state.modelInfoScreen.formError = "";
        callback(this.state);
      }, 5000);
    } else if (this.state.modelInfoScreen.modelName.includes("/")) {
      this.state.modelInfoScreen.formError =
        "De naam van het model mag geen '/' bevatten.";
      callback(this.state);
      setTimeout(() => {
        this.state.modelInfoScreen.formError = "";
        callback(this.state);
      }, 5000);
    } else {
      this.state.loading = true;
      callback(this.state);

      if (this.state.modelInfoScreen.modelId) {
        this.modelApi
          .apiGrexmanagerModelPartialUpdate({
            id: this.state.modelInfoScreen.modelId,
            patchedModelPartialUpdateRequest: {
              description:
                this.state.modelInfoScreen.description === ""
                  ? null
                  : this.state.modelInfoScreen.description,
              name: this.state.modelInfoScreen.modelName,
              frozen:
                this.state.modelInfoScreen.projectRole === RoleEnum.Adm
                  ? this.state.modelInfoScreen.frozen
                  : undefined,
            },
          })
          .then((_) => {
            this.state.loading = false;
            callback(this.state);
            this.changeScreen(ScreenState.Projects, callback);
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 409) {
              this.state.modelInfoScreen.formError =
                "Er bestaat al een model met deze naam.";
            } else {
              this.state.modelInfoScreen.formError =
                "Er ging iets mis tijdens het opslaan.";
            }
            this.state.loading = false;
            callback(this.state);
            setTimeout(() => {
              this.state.modelInfoScreen.formError = "";
              callback(this.state);
            }, 5000);
          });
      }
    }
  }

  loadModelHistory(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.currentUser.isSuperAdmin &&
      this.state.modelInfoScreen.modelId
    ) {
      this.state.modelInfoScreen.modelHistoryList = [];
      this.modelApi
        .apiGrexmanagerModelSavedModelList({
          id: this.state.modelInfoScreen.modelId as number,
        })
        .then((response) => {
          response.forEach((model) => {
            this.state.modelInfoScreen.modelHistoryList.push({
              id: model.id,
              active: model.active === true ? true : false,
              fileSize: model.filesize || 0,
              filenameOrig: model.filenameOrig,
              timestampSave: model.timestampSave,
              userSave: model.userSave,
            });
          });
          callback(this.state);
        });
      callback(this.state);
    }
  }

  activateModelHistory(
    this: AppStateHandler,
    historyId: number,
    callback: (newState: AppStateType) => void
  ) {
    // TODO: Activate different model history and reload list
    if (
      this.state.modelInfoScreen.modelId &&
      this.state.currentUser.isSuperAdmin
    ) {
      this.modelApi
        .apiGrexmanagerModelSavedModelPartialUpdate({
          id: this.state.modelInfoScreen.modelId,
          savedModelId: historyId,
        })
        .then((response) => {
          callback(this.state);
          this.loadModelHistory(callback);
        })
        .catch((error) => {
          this.state.modelInfoScreen.formError = "Error tijdens activeren.";
          callback(this.state);
          console.log(error);
          setTimeout(() => {
            this.state.modelInfoScreen.formError = "";
            callback(this.state);
          }, 2000);
        });
    }
  }

  downloadModelHistory(
    this: AppStateHandler,
    historyId: number,
    filenameOrig: string,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.currentUser.isSuperAdmin &&
      this.state.modelInfoScreen.modelId
    ) {
      this.modelApi
        .apiGrexmanagerModelSavedModelRetrieve({
          id: this.state.modelInfoScreen.modelId,
          savedModelId: historyId,
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filenameOrig;
          document.body.appendChild(link);
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          document.body.removeChild(link);
        })
        .catch((error) => {
          this.state.modelInfoScreen.formError =
            "Er ging iets mis tijdens het downloaden.";
          callback(this.state);
          setTimeout(() => {
            this.state.modelInfoScreen.formError = "";
            callback(this.state);
          }, 5000);
          console.log(error);
        });
    }
  }

  updateModelHistoryFile(
    this: AppStateHandler,
    files: FileList | null,
    callback: (newState: AppStateType) => void
  ) {
    if (files !== null && this.state.currentUser.isSuperAdmin) {
      this.state.modelInfoScreen.newHistoryFile = files[0];
      callback(this.state);
    }
  }

  saveModelHistoryFile(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.modelInfoScreen.modelId &&
      this.state.modelInfoScreen.newHistoryFile &&
      this.state.currentUser.isSuperAdmin
    ) {
      // Upload the file
      var formData = new FormData();
      formData.append("file", this.state.modelInfoScreen.newHistoryFile);
      const url = `${this.basePath()}/api/grexmanager/model/${
        this.state.modelInfoScreen.modelId
      }/saved-model/`;
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (e) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // Success message
            this.state.modelInfoScreen.newHistoryFile = null;
            this.state.modelInfoScreen.fileUploadSuccessMessage =
              "Upload geslaagd";
            callback(this.state);
            setTimeout(() => {
              this.state.modelInfoScreen.fileUploadSuccessMessage = "";
              callback(this.state);
            }, 2000);

            // Reload list
            this.loadModelHistory(callback);
          } else {
            this.state.modelInfoScreen.fileUploadErrorMessage =
              "Upload niet geslaagd.";
            callback(this.state);
            setTimeout(() => {
              this.state.modelInfoScreen.fileUploadErrorMessage = "";
              callback(this.state);
            }, 2000);
          }
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.state.currentUser.accessToken || ""}`
      );
      xhr.send(formData);
    } else {
      this.state.modelInfoScreen.fileUploadErrorMessage =
        "Selecteer een bestand.";
      callback(this.state);
      setTimeout(() => {
        this.state.modelInfoScreen.fileUploadErrorMessage = "";
        callback(this.state);
      }, 2000);
    }
  }
}
