import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import QrCode from "@shared/components/QrCode";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./SetupMfaScreen.css";
interface SetupMfaScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class SetupMfaScreen extends React.Component<SetupMfaScreenProps> {
  render() {
    return (
      <div id="SetupMfaScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Gebruikersinstellingen",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.User,
                  this.props.updateStateCallback
                );
              },
            },
            { title: "Multi-Factor Authenticatie (MFA) instellen" },
          ]}
        ></BreadCrumbs>

        <Section>
          <SectionTitle>Multi-Factor Authenticatie (MFA)</SectionTitle>
          <table className="SetupMfaScreen-Table">
            <tbody>
              {(() => {
                if (this.props.state.currentUser.mfaForced) {
                  return (
                    <tr>
                      <td>
                        Het instellen van MFA is vereist om door te gaan en
                        GrexManager te gebruiken. Om MFA in te stellen voor uw
                        account, volgt u de onderstaande stappen:
                      </td>
                    </tr>
                  );
                }
              })()}

              <tr>
                <td>
                  1. Download een authenticator-app op uw mobiele apparaat.
                  Populaire opties zijn Google Authenticator, Authy of Microsoft
                  Authenticator.
                </td>
              </tr>

              <tr>
                <td>
                  2. Scan de QR-code hieronder met de authenticator-app op uw
                  mobiele apparaat. Als alternatief kunt u de handmatige code
                  gebruiken die wordt weergegeven in de app.
                </td>
              </tr>
              <tr>
                <td>
                  <div className="SetupMfaScreenQRContainer">
                    <QrCode
                      url={this.props.state.setupMfa.otpauth_url}
                    ></QrCode>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  Als alternatief kunt u de handmatige code gebruiken die wordt
                  weergegeven in de app.
                </td>
              </tr>
              <tr>
                <td>
                  <div className="SetupMfaScreenQRContainer">
                    <p>
                      <i>{this.props.state.setupMfa.setup_key}</i>
                    </p>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  3. Nadat u de QR-code heeft gescand of de handmatige code
                  heeft ingevoerd, genereert de authenticator-app een unieke
                  code. Voer deze code in het daarvoor bestemde veld in om uw
                  apparaat te verifiëren.
                </td>
              </tr>
              <tr>
                <td>
                  {(() => {
                    if (this.props.state.setupMfa.errorMessage !== "") {
                      return (
                        <Alert
                          Error={true}
                          Message={this.props.state.setupMfa.errorMessage}
                        ></Alert>
                      );
                    }
                  })()}
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="XXXXXX"
                    value={this.props.state.currentUser.mfaInput}
                    onChange={(newValue: string) => {
                      this.props.stateHandler.VerifyMfaCode(
                        newValue,
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>
        <ButtonList
          data={[
            {
              title: "Annuleren",
              callback: () => {
                this.props.stateHandler.disableMFA(
                  this.props.updateStateCallback
                );
                this.props.stateHandler.changeScreen(
                  ScreenState.User,
                  this.props.updateStateCallback
                );
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
