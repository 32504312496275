import { RoleEnum } from "@shared/client/lib";
import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import DropDown from "@shared/components/DropDown";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import {
  IndexToRoleEnum,
  RoleEnumToIndex,
  RoleProjectEnumToDropDown,
  dropDownListPrintItem,
  formatDate,
} from "@shared/utils/helperFunctions";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./ProjectInfoScreen.css";

interface ProjectInfoScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ProjectInfoScreen extends React.Component<ProjectInfoScreenProps> {
  render() {
    return (
      <div id="ProjectInfoScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Projecten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Project info",
            },
          ]}
        ></BreadCrumbs>
        <Section disabled={this.props.state.projectInfoScreen.loadingForm}>
          {(() => {
            if (this.props.state.projectInfoScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.projectInfoScreen.formError}
                ></Alert>
              );
            }
          })()}
          <table className="ProjectInfoScreen-Table">
            <tbody>
              <tr>
                <td>Projectnaam*</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <TextInput
                          type={TextInputType.Text}
                          placeholder="Projectnaam"
                          onChange={(newValue) => {
                            this.props.stateHandler.projectInfoUpdateForm(
                              { projectName: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                          value={this.props.state.projectInfoScreen.projectName}
                        ></TextInput>
                      );
                    } else {
                      return this.props.state.projectInfoScreen.projectName;
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Beschrijving</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <TextInput
                          type={TextInputType.Text}
                          placeholder="Beschrijving"
                          onChange={(newValue) => {
                            this.props.stateHandler.projectInfoUpdateForm(
                              { description: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                          value={this.props.state.projectInfoScreen.description}
                        ></TextInput>
                      );
                    } else {
                      return this.props.state.projectInfoScreen.description ===
                        ""
                        ? "-"
                        : this.props.state.projectInfoScreen.description;
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Projectnummer</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <TextInput
                          type={TextInputType.Text}
                          placeholder="Projectnummer"
                          onChange={(newValue) => {
                            this.props.stateHandler.projectInfoUpdateForm(
                              { projectNumber: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                          value={
                            this.props.state.projectInfoScreen.projectNumber
                          }
                        ></TextInput>
                      );
                    } else {
                      return this.props.state.projectInfoScreen
                        .projectNumber === ""
                        ? "-"
                        : this.props.state.projectInfoScreen.projectNumber;
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Projecttype*</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <DropDown
                          list={
                            this.props.state.projectInfoScreen.typeProjectList
                          }
                          onChange={(newKey) => {
                            this.props.stateHandler.projectInfoUpdateForm(
                              { typeProject: newKey },
                              this.props.updateStateCallback
                            );
                          }}
                          selectedKey={
                            this.props.state.projectInfoScreen.typeProject
                          }
                        ></DropDown>
                      );
                    } else {
                      return dropDownListPrintItem(
                        this.props.state.projectInfoScreen.typeProjectList,
                        this.props.state.projectInfoScreen.typeProject
                      );
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Deelgebied*</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <DropDown
                          list={this.props.state.projectInfoScreen.subregions}
                          onChange={(newKey) => {
                            this.props.stateHandler.projectInfoSubregionChanged(
                              newKey,
                              this.props.updateStateCallback
                            );
                          }}
                          selectedKey={
                            this.props.state.projectInfoScreen.subregion
                          }
                        ></DropDown>
                      );
                    } else {
                      return dropDownListPrintItem(
                        this.props.state.projectInfoScreen.subregions,
                        this.props.state.projectInfoScreen.subregion
                      );
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Gemeente / organisatie</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <TextInput
                          type={TextInputType.Text}
                          placeholder="Gemeente / organisatie"
                          onChange={(newValue) => {
                            this.props.stateHandler.projectInfoUpdateForm(
                              { organisation: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                          value={
                            this.props.state.projectInfoScreen.organisation
                          }
                        ></TextInput>
                      );
                    } else {
                      return this.props.state.projectInfoScreen.organisation ===
                        ""
                        ? "-"
                        : this.props.state.projectInfoScreen.organisation;
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Projectmanager</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.projectInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <TextInput
                          type={TextInputType.Text}
                          placeholder="Projectmanager"
                          onChange={(newValue) => {
                            this.props.stateHandler.projectInfoUpdateForm(
                              { projectManager: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                          value={
                            this.props.state.projectInfoScreen.projectManager
                          }
                        ></TextInput>
                      );
                    } else {
                      return this.props.state.projectInfoScreen
                        .projectManager === ""
                        ? "-"
                        : this.props.state.projectInfoScreen.projectManager;
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Auteur</td>
                <td>{this.props.state.projectInfoScreen.author}</td>
              </tr>
              <tr>
                <td>Laatst gewijzigd</td>
                <td>
                  {this.props.state.projectInfoScreen.lastEdited
                    ? formatDate(this.props.state.projectInfoScreen.lastEdited)
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Laatst gewijzigd door</td>
                <td>
                  {this.props.state.projectInfoScreen.lastModifiedByNameFull}
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        {(() => {
          if (this.props.state.projectInfoScreen.projectRole === RoleEnum.Adm) {
            return (
              <Section
                disabled={this.props.state.projectInfoScreen.loadingRoles}
              >
                <table className="ProjectInfoScreen-Table">
                  <thead>
                    <tr>
                      <th>Naam</th>
                      <th className="ProjectInfoScreen-CellWidth">Rechten</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.state.projectInfoScreen.userRoles.map(
                      (item) => {
                        return (
                          <tr key={item.id}>
                            <td>{item.nameFull}</td>
                            <td>
                              <DropDown
                                list={RoleProjectEnumToDropDown()}
                                onChange={(newKey) => {
                                  this.props.stateHandler.projectInfoChangeRole(
                                    item.id,
                                    IndexToRoleEnum(newKey),
                                    this.props.updateStateCallback
                                  );
                                }}
                                selectedKey={RoleEnumToIndex(item.role)}
                              ></DropDown>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </Section>
            );
          }
        })()}

        {(() => {
          if (
            this.props.state.projectInfoScreen.projectRole === RoleEnum.Adm ||
            this.props.state.projectInfoScreen.projectRole === RoleEnum.Usr
          ) {
            return (
              <ButtonList
                data={[
                  {
                    title: "Opslaan",
                    callback: () => {
                      this.props.stateHandler.projectInfoSave(
                        this.props.updateStateCallback
                      );
                    },
                    disabled:
                      this.props.state.projectInfoScreen.loadingForm ||
                      this.props.state.projectInfoScreen.loadingRoles,
                  },
                  {
                    title: "Annuleren",
                    callback: () => {
                      this.props.stateHandler.changeScreen(
                        ScreenState.Projects,
                        this.props.updateStateCallback
                      );
                    },
                  },
                ]}
              ></ButtonList>
            );
          }
        })()}
      </div>
    );
  }
}
