import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiPlus, FiSave } from "react-icons/fi";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./TypeProjectScreen.css";

interface TypeProjectScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class TypeProjectScreen extends React.Component<TypeProjectScreenProps> {
  render() {
    return (
      <div id="TypeProjectScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Configuratie",
            },
            {
              title: "Projecttypen",
            },
          ]}
        ></BreadCrumbs>

        <Section>
          {(() => {
            if (this.props.state.typeProjectScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.typeProjectScreen.formError}
                ></Alert>
              );
            }
          })()}
          <div>
            <table className="TypeProjectScreen-Table">
              <tbody>
                <tr>
                  <th>Omschrijving</th>
                </tr>
                {this.props.state.typeProjectScreen.typeProjectList.map(
                  (item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <div className="TypeProjectRowContainer">
                            <div className="TypeProjectDescBox">
                              <TextInput
                                type={TextInputType.Text}
                                placeholder={item.description}
                                value={item.newDescription}
                                onChange={(newvalue) => {
                                  this.props.stateHandler.onTypeProjectEditInputChange(
                                    item.id,
                                    newvalue,
                                    this.props.updateStateCallback
                                  );
                                }}
                              ></TextInput>
                            </div>
                            <div className="TypeProjectActionBox">
                              <div
                                className="TypeProjectScreen-ListItem"
                                onClick={() => {
                                  this.props.stateHandler.onTypeProjectDeleteClick(
                                    item.id,
                                    this.props.updateStateCallback
                                  );
                                }}
                              >
                                <AiOutlineDelete />
                              </div>
                            </div>

                            <div
                              className="TypeProjectActionBox"
                              style={{
                                display: item.saveButtonVisible
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <div
                                className="TypeProjectScreen-ListItem"
                                onClick={() => {
                                  this.props.stateHandler.onTypeProjectEditClick(
                                    item.id,
                                    item.newDescription,
                                    this.props.updateStateCallback
                                  );
                                }}
                              >
                                <FiSave />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
                <div className="line"></div>
                <tr>
                  <th>Projecttype toevoegen</th>
                </tr>
                <tr>
                  <td>
                    <div className="TypeProjectRowContainer">
                      <div className="TypeProjectDescBox">
                        <TextInput
                          value={
                            this.props.state.typeProjectScreen.newTypeProject
                          }
                          type={TextInputType.Text}
                          placeholder="Projecttype"
                          onChange={(newvalue) => {
                            this.props.stateHandler.onNewTypeProjectInputChange(
                              newvalue,
                              this.props.updateStateCallback
                            );
                          }}
                        ></TextInput>
                      </div>

                      <div className="TypeProjectActionBox">
                        <div
                          className="TypeProjectScreen-ListItem"
                          onClick={() => {
                            this.props.stateHandler.onTypeProjectAddClick(
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          <FiPlus />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Section>
      </div>
    );
  }
}
