import { RoleEnum } from "@shared/client/lib";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class LoginMfaStateHandler {
  LoginMfaVerifyCode(
    this: AppStateHandler,
    mfa_code: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.currentUser.mfaInputTwo = mfa_code;
    callback(this.state);

    if (mfa_code.length === 6) {
      this.authApi
        .apiGrexmanagerUserLoginMfaCreate({
          login2fa: {
            email: this.state.login2fa.useremail,
            mfaCode: mfa_code,
          },
        })
        .then((response: any) => {
          if (response.access) {
            this.setAccessToken(response.access, callback);

            // Get the role
            this.userApi
              .apiGrexmanagerUserMeRetrieve()
              .then((response) => {
                this.state.currentUser.isSuperAdmin = response.isStaff || false;
                this.state.currentUser.mfaInputTwo = "";
                this.state.currentUser.userRole = response.role || RoleEnum.Non;
                this.state.currentUser.passwordResetRequired =
                  response.passwordResetRequired || false;
                this.state.loggedIn = true;
                this.state.mfaRequired = false;
                this.state.login2fa.errorMessage = "";
                callback(this.state);
                this.changeScreen(ScreenState.Projects, callback);
              })
              .catch((error) => {
                console.log(error);
                this.state.currentUser.mfaInputTwo = "";
                this.state.login2fa.errorMessage = "Server error";
                callback(this.state);
              });
          } else {
            this.state.currentUser.mfaInputTwo = "";
            this.state.login2fa.errorMessage = "Ongeldige code!";
            callback(this.state);
          }
        })
        .catch((error) => {
          this.state.currentUser.mfaInputTwo = "";
          this.state.login2fa.errorMessage = "Ongeldige code!";
          callback(this.state);
        });
    } else {
      this.state.login2fa.errorMessage = "";
      callback(this.state);
    }
  }
}
