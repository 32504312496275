import { RoleEnum } from "@shared/client/lib";
import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import Button from "@shared/components/Button";
import ButtonList from "@shared/components/ButtonList";
import CheckBox from "@shared/components/CheckBox";
import FileInput from "@shared/components/FileInput";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import {
  bytesToString,
  dropDownListPrintItem,
  formatDate,
} from "@shared/utils/helperFunctions";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./ModelInfoScreen.css";

interface ModelInfoScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ModelInfoScreen extends React.Component<ModelInfoScreenProps> {
  render() {
    return (
      <div id="ModelInfoScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Projecten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Rekenmodel Info",
            },
          ]}
        ></BreadCrumbs>

        <Section disabled={this.props.state.modelInfoScreen.loadingForm}>
          {(() => {
            if (this.props.state.modelInfoScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.modelInfoScreen.formError}
                ></Alert>
              );
            }
          })()}
          <table className="ModelInfoScreen-Table">
            <tbody>
              <tr>
                <td>Naam rekenmodel*</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.modelInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.modelInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <TextInput
                          type={TextInputType.Text}
                          placeholder="Naam rekenmodel"
                          onChange={(newValue) => {
                            this.props.stateHandler.modelInfoUpdateForm(
                              { modelName: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                          value={this.props.state.modelInfoScreen.modelName}
                        ></TextInput>
                      );
                    } else {
                      return this.props.state.modelInfoScreen.modelName === ""
                        ? "-"
                        : this.props.state.modelInfoScreen.modelName;
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Beschrijving</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.modelInfoScreen.projectRole ===
                        RoleEnum.Adm ||
                      this.props.state.modelInfoScreen.projectRole ===
                        RoleEnum.Usr
                    ) {
                      return (
                        <TextInput
                          type={TextInputType.Text}
                          placeholder="Beschrijving"
                          onChange={(newValue) => {
                            this.props.stateHandler.modelInfoUpdateForm(
                              { description: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                          value={this.props.state.modelInfoScreen.description}
                        ></TextInput>
                      );
                    } else {
                      return this.props.state.modelInfoScreen.description === ""
                        ? "-"
                        : this.props.state.modelInfoScreen.description;
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Type rekenmodel*</td>
                <td>
                  {this.props.state.modelInfoScreen.modelType === 0
                    ? "-"
                    : dropDownListPrintItem(
                        this.props.state.modelInfoScreen.modelTypes,
                        this.props.state.modelInfoScreen.modelType
                      )}
                </td>
              </tr>
              <tr>
                <td>Vergrendeld</td>
                <td>
                  {(() => {
                    if (
                      this.props.state.modelInfoScreen.projectRole ===
                      RoleEnum.Adm
                    ) {
                      return (
                        <CheckBox
                          checked={this.props.state.modelInfoScreen.frozen}
                          onClick={(newValue) => {
                            this.props.stateHandler.modelInfoUpdateForm(
                              { frozen: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                        ></CheckBox>
                      );
                    } else {
                      return this.props.state.modelInfoScreen.frozen
                        ? "Ja"
                        : "Nee";
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Auteur</td>
                <td>{this.props.state.modelInfoScreen.author}</td>
              </tr>
              <tr>
                <td>Laatst gewijzigd</td>
                <td>
                  {this.props.state.modelInfoScreen.lastEdited
                    ? formatDate(this.props.state.modelInfoScreen.lastEdited)
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Laatst gewijzigd door</td>
                <td>
                  {this.props.state.modelInfoScreen.lastModifiedByNameFull}
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          {(() => {
            if (
              this.props.state.modelInfoScreen.projectRole === RoleEnum.Adm ||
              this.props.state.modelInfoScreen.projectRole === RoleEnum.Usr
            ) {
              return (
                <ButtonList
                  data={[
                    {
                      title: "Opslaan",
                      callback: () => {
                        this.props.stateHandler.modelInfoSave(
                          this.props.updateStateCallback
                        );
                      },
                      disabled: this.props.state.modelInfoScreen.loadingForm,
                    },
                    {
                      title: "Annuleren",
                      callback: () => {
                        this.props.stateHandler.changeScreen(
                          ScreenState.Projects,
                          this.props.updateStateCallback
                        );
                      },
                    },
                  ]}
                ></ButtonList>
              );
            }
          })()}
        </Section>

        {(() => {
          if (this.props.state.currentUser.isSuperAdmin) {
            return (
              <>
                <Section disabled={false}>
                  {(() => {
                    if (
                      this.props.state.modelInfoScreen
                        .fileUploadErrorMessage !== ""
                    ) {
                      return (
                        <Alert
                          Error={true}
                          Message={
                            this.props.state.modelInfoScreen
                              .fileUploadErrorMessage
                          }
                        ></Alert>
                      );
                    }
                    if (
                      this.props.state.modelInfoScreen
                        .fileUploadSuccessMessage !== ""
                    ) {
                      return (
                        <Alert
                          Error={false}
                          Message={
                            this.props.state.modelInfoScreen
                              .fileUploadSuccessMessage
                          }
                        ></Alert>
                      );
                    }
                  })()}
                  <SectionTitle subtitle={true}>
                    Rekenmodel toevoegen aan historie
                  </SectionTitle>
                  <table className="ModelInfoScreen-Table">
                    <tbody>
                      <tr>
                        <td>Bestand*</td>
                        <td>
                          <FileInput
                            onChange={(event) => {
                              this.props.stateHandler.updateModelHistoryFile(
                                event.target.files,
                                this.props.updateStateCallback
                              );
                            }}
                          ></FileInput>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <ButtonList
                    data={[
                      {
                        title: "Upload",
                        callback: () => {
                          this.props.stateHandler.saveModelHistoryFile(
                            this.props.updateStateCallback
                          );
                        },
                        disabled: false,
                      },
                    ]}
                  ></ButtonList>
                </Section>

                <Section
                  disabled={this.props.state.modelInfoScreen.loadingForm}
                >
                  <SectionTitle subtitle={true}>
                    Rekenmodel historie
                  </SectionTitle>
                  <table className="ModelInfoScreen-Table">
                    <thead>
                      <tr>
                        <th>Datum</th>
                        <th>Gebruiker</th>
                        <th>Bestandsgrootte</th>
                        <th>Actief</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.state.modelInfoScreen.modelHistoryList.map(
                        (item) => {
                          return (
                            <tr key={item.id}>
                              <td>{formatDate(item.timestampSave)}</td>
                              <td>{item.userSave}</td>
                              <td>{bytesToString(item.fileSize)}</td>
                              <td
                                onClick={(event) => {
                                  this.props.stateHandler.activateModelHistory(
                                    item.id,
                                    this.props.updateStateCallback
                                  );
                                }}
                                className="ModelInfoScreen-ActiveButton"
                              >
                                {item.active ? "v" : "-"}
                              </td>
                              <td>
                                <Button
                                  onClick={() => {
                                    this.props.stateHandler.downloadModelHistory(
                                      item.id,
                                      item.filenameOrig,
                                      this.props.updateStateCallback
                                    );
                                  }}
                                >
                                  Download
                                </Button>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </Section>
              </>
            );
          }
        })()}
      </div>
    );
  }
}
