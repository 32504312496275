import React from "react";
import "./TextInput.css";

export enum TextInputType {
  Text,
  Email,
  Password,
  NewPassword,
  Date,
}

interface TextInputProps {
  type: TextInputType;
  value: string;
  placeholder?: string;
  onChange: (newValue: string) => void;
}

export class TextInput extends React.Component<TextInputProps> {
  state = {
    focused: false,
  };
  inputType = "text";
  autoComplete = "off";

  onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.props.onChange(event.target.value);
  };

  onFocus: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.state.focused = true;
    this.setState(this.state);
  };

  onBlur: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.state.focused = false;
    this.setState(this.state);
  };

  componentDidMount() {
    if (this.props.type === TextInputType.Password) {
      this.inputType = "password";
      this.autoComplete = "password";
    } else if (this.props.type === TextInputType.NewPassword) {
      this.inputType = "password";
      this.autoComplete = "new-password";
    } else if (this.props.type === TextInputType.Email) {
      this.inputType = "email";
    } else if (this.props.type === TextInputType.Date) {
      this.inputType = "date";
    }
  }

  render() {
    return (
      <div
        className={
          this.state.focused
            ? "TextInput-Background TextInput-BackgroundFocused"
            : "TextInput-Background"
        }
      >
        <input
          className="TextInput-Input"
          type={this.inputType}
          value={this.props.value}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          autoComplete={this.autoComplete}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}
