import {
  ModelListResponse,
  ParameterListTypeEnum,
  PeriodTypeEnum,
} from "@shared/client/lib";
import { DropDownListItem } from "@shared/components/DropDown";
import _cloneDeep from "lodash/cloneDeep";
import { AppStateHandler } from "src/AppStateHandler";
import { AppStateType, ProjectSortOption, ScreenState } from "src/Types";
import { globalParameterDefaultValues } from "./GlobalParameterDefaultValues";

export class GlobalParameterStateHandler {
  globalParameterScreenSortProjectList(this: AppStateHandler) {
    // Alphabetical
    if (
      this.state.globalParameterScreen.projectSorting ===
      ProjectSortOption.Alphabetical
    ) {
      this.state.globalParameterScreen.projectsList.sort((a, b) => {
        const nameA = a.name.toLowerCase(); // ignore upper and lowercase
        const nameB = b.name.toLowerCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    // ProjectNumber
    else if (
      this.state.globalParameterScreen.projectSorting ===
      ProjectSortOption.ProjectNumber
    ) {
      this.state.globalParameterScreen.projectsList.sort((a, b) => {
        if (a.number == null && b.number != null) {
          return 1;
        }
        if (a.number != null && b.number == null) {
          return -1;
        }
        if (a.number != null && b.number != null) {
          const numberA = a.number.toLowerCase(); // ignore upper and lowercase
          const numberB = b.number.toLowerCase(); // ignore upper and lowercase
          if (numberA < numberB) {
            return -1;
          }
          if (numberA > numberB) {
            return 1;
          }
        }
        return 0;
      });
    }
    // Last edited
    else if (
      this.state.globalParameterScreen.projectSorting ===
      ProjectSortOption.LastEdited
    ) {
      this.state.globalParameterScreen.projectsList.sort((a, b) => {
        const timeA = a.timestampLastModified; // ignore upper and lowercase
        const timeB = b.timestampLastModified; // ignore upper and lowercase
        if (timeA < timeB) {
          return 1;
        }
        if (timeA > timeB) {
          return -1;
        }
        return 0;
      });
    }
  }

  initGlobalParameterScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Configuration_GlobalParameters;

    // Start with default values
    this.state.globalParameterScreen = {
      page: 0,
      parameters: [],
      uniqueParameterTypes: [],
      selectedParameterType: null,
      loadingSpinner: false,
      loadingProjects: false,
      projectsHaveModelsList: [],
      projectsList: [],
      selectedProject: null,
      loadingVersionModels: false,
      versionsList: [],
      selectedVersionModel: null,
      loadingModels: false,
      modelsList: [],
      allProjectsSelected: false,
      selectedModels: [],
      typeProject: [],
      filterTypeProject: 0,
      subregions: [],
      filterSubregion: 0,
      projectSorting: ProjectSortOption.LastEdited,
      loadAndSelectModel: false,
      errorMessage: "",
    };

    this.state.globalParameterScreen.parameters = _cloneDeep(
      globalParameterDefaultValues
    );

    // Get all the unique used parameter types
    this.state.globalParameterScreen.uniqueParameterTypes =
      this.state.globalParameterScreen.parameters
        .map((p) => p.type)
        .filter((value, index, self) => self.indexOf(value) === index);

    // Select the first parameter type
    if (this.state.globalParameterScreen.uniqueParameterTypes.length > 0) {
      this.state.globalParameterScreen.selectedParameterType =
        this.state.globalParameterScreen.uniqueParameterTypes[0];
    }
    callback(this.state);

    // Get typeProject
    this.typeProjectApi
      .apiGrexmanagerTypeProjectList()
      .then((typeProject) => {
        this.state.globalParameterScreen.typeProject = [
          {
            key: 0,
            name: "-- alle projecttypen --",
            disabled: false,
          },
        ].concat(
          typeProject.map((x) => {
            const item: DropDownListItem = {
              key: x.id,
              name: x.description,
              disabled: false,
            };
            return item;
          })
        );
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);

        this.state.globalParameterScreen.errorMessage =
          "Fout bij het laden van projecttypen.";
        callback(this.state);
        setTimeout(() => {
          this.state.globalParameterScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });

    // Get subregions
    this.subregionApi
      .apiGrexmanagerSubregionList()
      .then((subregions) => {
        this.state.globalParameterScreen.subregions = [
          {
            key: 0,
            name: "-- alle deelgebieden --",
            disabled: false,
          },
        ].concat(
          subregions.map((x) => {
            const item: DropDownListItem = {
              key: x.id,
              name: x.description,
              disabled: false,
            };
            return item;
          })
        );
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.globalParameterScreen.errorMessage =
          "Fout bij het laden van deelgebieden.";
        callback(this.state);
        setTimeout(() => {
          this.state.globalParameterScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });

    this.globalParameterScreenReloadProjects(callback);
  }

  parameterTypeClick(
    this: AppStateHandler,
    type: ParameterListTypeEnum,
    callback: (newState: AppStateType) => void
  ) {
    this.state.globalParameterScreen.selectedParameterType = type;
    callback(this.state);
  }

  globalParameterChangeBaseParameter(
    this: AppStateHandler,
    type: ParameterListTypeEnum,
    newValue: string,
    callback: (newState: AppStateType) => void
  ) {
    // At this point newValue should be a valid string, this is checkend in onGlobalParameterInputChange
    const paramaterTypesWithBaseParameter: ParameterListTypeEnum[] = [
      ParameterListTypeEnum.Kostenstijging,
      ParameterListTypeEnum.Opbrengstenstijging,
      ParameterListTypeEnum.RentepercentageNegatiefSaldo,
      ParameterListTypeEnum.RentepercentagePositiefSaldo,
    ];
    if (paramaterTypesWithBaseParameter.includes(type)) {
      // Find parameters to update
      const parametersToUpdate =
        this.state.globalParameterScreen.parameters.filter(
          (parameter, index, list) =>
            parameter.type === type &&
            parameter.periodType !== PeriodTypeEnum.Uitgangspunt
        );

      // Update values
      parametersToUpdate.forEach((parameter) => {
        parameter.valueString = newValue;
      });
      callback(this.state);
    }
  }

  onGlobalParameterInputChange(
    this: AppStateHandler,
    itemIndex: number,
    newValue: string,
    callback: (newState: AppStateType) => void
  ) {
    const paramaterTypesDates: ParameterListTypeEnum[] = [
      ParameterListTypeEnum.Eindwaardedatum,
      ParameterListTypeEnum.NcwDatum,
      ParameterListTypeEnum.Prijspeildatum,
      ParameterListTypeEnum.StartdatumGrondexploitatieBoekwaardeVanaf,
    ];

    if (itemIndex < this.state.globalParameterScreen.parameters.length) {
      if (
        paramaterTypesDates.includes(
          this.state.globalParameterScreen.parameters[itemIndex].type
        )
      ) {
        const dateRegex = /^[1-3][0-9]{3}-[0-9]{2}-[0-9]{2}$/;
        if (dateRegex.test(newValue)) {
          this.state.globalParameterScreen.parameters[itemIndex].valueDate =
            new Date(newValue);
          callback(this.state);
        } else {
          this.state.globalParameterScreen.errorMessage = "Ongeldige invoer.";
          callback(this.state);
          setTimeout(() => {
            this.state.globalParameterScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        }
      } else {
        const percentageRegex = /^(?:[0-1](?:\.[0-9]*)?)?$/;
        if (percentageRegex.test(newValue)) {
          if (
            newValue === "" ||
            (parseFloat(newValue) >= 0 && parseFloat(newValue) <= 1)
          ) {
            this.state.globalParameterScreen.parameters[itemIndex].valueString =
              newValue;
            callback(this.state);

            if (
              this.state.globalParameterScreen.parameters[itemIndex]
                .periodType === PeriodTypeEnum.Uitgangspunt
            ) {
              this.globalParameterChangeBaseParameter(
                this.state.globalParameterScreen.parameters[itemIndex].type,
                newValue,
                callback
              );
            }
          } else {
            this.state.globalParameterScreen.errorMessage = "Ongeldige invoer.";
            callback(this.state);
            setTimeout(() => {
              this.state.globalParameterScreen.errorMessage = "";
              callback(this.state);
            }, 5000);
          }
        } else {
          this.state.globalParameterScreen.errorMessage = "Ongeldige invoer.";
          callback(this.state);
          setTimeout(() => {
            this.state.globalParameterScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        }
      }
    }
  }

  globalParameterAddColumn(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.globalParameterScreen.selectedParameterType !== undefined &&
      this.state.globalParameterScreen.selectedParameterType !== null
    ) {
      const flexibleColumnTypes: ParameterListTypeEnum[] = [
        ParameterListTypeEnum.HistorischeIndexGebruiker,
        ParameterListTypeEnum.ToekomstigeIndexGebruiker,
      ];

      if (
        flexibleColumnTypes.includes(
          this.state.globalParameterScreen.selectedParameterType
        )
      ) {
        // Filter down to get the first column for this type
        const firstColumn = this.state.globalParameterScreen.parameters.filter(
          (parameter, index, list) =>
            parameter.type ===
              this.state.globalParameterScreen.selectedParameterType &&
            parameter.rowIndex === 0
        );

        // Find the max rowIndex for this type
        const maxIndex = Math.max(
          ...this.state.globalParameterScreen.parameters
            .filter(
              (parameter, index, list) =>
                parameter.type ===
                this.state.globalParameterScreen.selectedParameterType
            )
            .map((p) => p.rowIndex ?? -1)
        );

        if (maxIndex < 999) {
          // Copy the first column but with an updated rowIndex and a new valueString
          firstColumn.forEach((parameter) => {
            if (
              this.state.globalParameterScreen.selectedParameterType !==
                undefined &&
              this.state.globalParameterScreen.selectedParameterType !== null
            ) {
              this.state.globalParameterScreen.parameters.push({
                type: this.state.globalParameterScreen.selectedParameterType,
                rowIndex: maxIndex + 1,
                periodType: parameter.periodType
                  ? parameter.periodType
                  : undefined,
                year: parameter.year ? parameter.year : undefined,
                valueString: "0.02",
                valueDate: undefined,
              });
            }
          });
          callback(this.state);
        }
      }
    }
  }

  globalParameterRemoveColumn(
    this: AppStateHandler,
    rowIndex: number,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.globalParameterScreen.selectedParameterType !== undefined &&
      this.state.globalParameterScreen.selectedParameterType !== null
    ) {
      const flexibleColumnTypes: ParameterListTypeEnum[] = [
        ParameterListTypeEnum.HistorischeIndexGebruiker,
        ParameterListTypeEnum.ToekomstigeIndexGebruiker,
      ];

      if (
        flexibleColumnTypes.includes(
          this.state.globalParameterScreen.selectedParameterType
        ) &&
        rowIndex > 0
      ) {
        // Remove all parameters with this type and this rowIndex
        this.state.globalParameterScreen.parameters =
          this.state.globalParameterScreen.parameters.filter(
            (parameter, index, list) =>
              !(
                parameter.type ===
                  this.state.globalParameterScreen.selectedParameterType &&
                parameter.rowIndex === rowIndex
              )
          );

        // Find the max rowIndex for this type
        const maxIndex = Math.max(
          ...this.state.globalParameterScreen.parameters
            .filter(
              (parameter, index, list) =>
                parameter.type ===
                this.state.globalParameterScreen.selectedParameterType
            )
            .map((p) => p.rowIndex ?? -1)
        );

        // Update all other rowIndexes that are following
        for (let i = rowIndex + 1; i <= maxIndex; i++) {
          const listToUpdate =
            this.state.globalParameterScreen.parameters.filter(
              (parameter, index, list) =>
                parameter.type ===
                  this.state.globalParameterScreen.selectedParameterType &&
                parameter.rowIndex === i
            );
          listToUpdate.forEach((parameter) => {
            parameter.rowIndex = parameter.rowIndex! - 1;
          });
        }
        callback(this.state);
      }
    }
  }

  globalParameterGoToFirstPage(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.globalParameterScreen.page = 0;
    callback(this.state);
  }

  globalParameterGoToSecondPage(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.globalParameterScreen.page = 1;
    callback(this.state);
  }

  globalParameterScreenReloadProjects(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.globalParameterScreen.projectsList = [];
    this.state.globalParameterScreen.versionsList = [];
    this.state.globalParameterScreen.modelsList = [];
    this.state.globalParameterScreen.loadingProjects = true;
    this.state.globalParameterScreen.loadingVersionModels = true;
    this.state.globalParameterScreen.loadingModels = true;
    callback(this.state);

    // Get projects
    this.projectApi
      .apiGrexmanagerProjectList()
      .then((projectsList) => {
        this.state.globalParameterScreen.projectsList = projectsList.map(
          (item) => {
            return {
              id: item.id,
              name: item.name,
              number: item.number,
              subregion: item.subregion,
              typeProject: item.typeProject,
              timestampLastModified: item.timestampLastModified,
              visible: true,
              role: item.role,
            };
          }
        );
        this.state.globalParameterScreen.loadingProjects = false;
        if (this.state.globalParameterScreen.projectsList.length >= 1) {
          this.globalParameterScreenSortProjectList();
          this.globalParameterScreenFilterProjectListAndSelectFirst();
        }
        callback(this.state);

        this.globalParameterScreenReloadVersionModels(callback);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.globalParameterScreen.errorMessage =
          "Fout bij het laden van projecten.";
        callback(this.state);
        setTimeout(() => {
          this.state.globalParameterScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }

  globalParameterScreenReloadVersionModels(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.globalParameterScreen.versionsList = [];
    this.state.globalParameterScreen.modelsList = [];
    this.state.globalParameterScreen.loadingVersionModels = true;
    this.state.globalParameterScreen.loadingModels = true;
    callback(this.state);

    // Get versions
    if (this.state.globalParameterScreen.selectedProject !== null) {
      this.versionModelApi
        .apiGrexmanagerVersionModelList({
          projectId: this.state.globalParameterScreen.selectedProject,
        })
        .then((versionModelList) => {
          this.state.globalParameterScreen.versionsList = versionModelList;
          this.state.globalParameterScreen.loadingVersionModels = false;

          if (this.state.globalParameterScreen.versionsList.length >= 1) {
            // select first one
            // TODO:
            if (this.state.globalParameterScreen.selectedVersionModel == null) {
              this.state.globalParameterScreen.selectedVersionModel =
                this.state.globalParameterScreen.versionsList[0].id;
            }
          }
          callback(this.state);

          this.globalParameterScreenReloadModels(callback);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.globalParameterScreen.errorMessage =
            "Fout bij het laden van versies.";
          callback(this.state);
          setTimeout(() => {
            this.state.globalParameterScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  globalParameterScreenReloadModels(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.globalParameterScreen.modelsList = [];
    this.state.globalParameterScreen.loadingModels = true;
    callback(this.state);

    // Get Models
    if (this.state.globalParameterScreen.selectedVersionModel !== null) {
      this.modelApi
        .apiGrexmanagerModelList({
          versionModelId: this.state.globalParameterScreen.selectedVersionModel,
        })
        .then((modelsList) => {
          this.state.globalParameterScreen.modelsList = modelsList;
          this.state.globalParameterScreen.loadingModels = false;
          callback(this.state);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.globalParameterScreen.errorMessage =
            "Fout bij het laden van rekenmodellen.";
          callback(this.state);
          setTimeout(() => {
            this.state.globalParameterScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  globalParameterScreenOnProjectFilterChange(
    this: AppStateHandler,
    filter: {
      typeProject?: number;
      subregion?: number;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (filter.typeProject !== undefined) {
      this.state.globalParameterScreen.filterTypeProject = filter.typeProject;
    }
    if (filter.subregion !== undefined) {
      this.state.globalParameterScreen.filterSubregion = filter.subregion;
    }
    this.globalParameterScreenFilterProjectListAndSelectFirst();
    // Deselect all
    this.state.globalParameterScreen.selectedModels = [];
    this.state.globalParameterScreen.allProjectsSelected = false;
    callback(this.state);
    this.globalParameterScreenReloadVersionModels(callback);
  }

  globalParameterScreenFilterProjectListAndSelectFirst(this: AppStateHandler) {
    this.state.globalParameterScreen.projectsList.forEach((project) => {
      if (
        this.state.globalParameterScreen.filterTypeProject === 0 &&
        this.state.globalParameterScreen.filterSubregion === 0
      ) {
        project.visible = true;
      } else if (
        this.state.globalParameterScreen.filterTypeProject ===
          project.typeProject &&
        this.state.globalParameterScreen.filterSubregion === 0
      ) {
        project.visible = true;
      } else if (
        this.state.globalParameterScreen.filterTypeProject === 0 &&
        this.state.globalParameterScreen.filterSubregion === project.subregion
      ) {
        project.visible = true;
      } else if (
        this.state.globalParameterScreen.filterTypeProject ===
          project.typeProject &&
        this.state.globalParameterScreen.filterSubregion === project.subregion
      ) {
        project.visible = true;
      } else {
        project.visible = false;
      }
    });

    // If the selected project became invisable then select the first visable project and reload versions and models
    if (this.state.globalParameterScreen.selectedProject) {
      const projectIndex =
        this.state.globalParameterScreen.projectsList.findIndex((object) => {
          return object.id === this.state.globalParameterScreen.selectedProject;
        });
      if (projectIndex > -1) {
        if (
          !this.state.globalParameterScreen.projectsList[projectIndex].visible
        ) {
          const visableProjects =
            this.state.globalParameterScreen.projectsList.filter((object) => {
              return object.visible;
            });
          if (visableProjects.length >= 1) {
            this.state.globalParameterScreen.selectedProject =
              visableProjects[0].id;
            this.state.globalParameterScreen.selectedVersionModel = null;
          } else {
            this.state.globalParameterScreen.selectedProject = null;
            this.state.globalParameterScreen.selectedVersionModel = null;
          }
        }
      }
    } else {
      const visableProjects =
        this.state.globalParameterScreen.projectsList.filter((object) => {
          return object.visible;
        });
      if (visableProjects.length >= 1) {
        this.state.globalParameterScreen.selectedProject =
          visableProjects[0].id;
        this.state.globalParameterScreen.selectedVersionModel = null;
      }
    }
  }

  globalParameterScreenUpdateProjectSorting(
    this: AppStateHandler,
    projectSorting: ProjectSortOption,
    callback: (newState: AppStateType) => void
  ) {
    this.state.globalParameterScreen.projectSorting = projectSorting;
    this.globalParameterScreenSortProjectList();
    callback(this.state);
  }

  globalParameterScreenSelectAfterLoad(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    // TODO: This might not be the most eligant solution
    if (this.state.globalParameterScreen.loadAndSelectModel) {
      if (this.state.globalParameterScreen.modelsList.length >= 1) {
        if (
          this.state.globalParameterScreen.selectedProject &&
          this.state.globalParameterScreen.selectedVersionModel
        ) {
          this.state.globalParameterScreen.selectedModels.push({
            project: this.state.globalParameterScreen.selectedProject,
            versionModel: this.state.globalParameterScreen.selectedVersionModel,
            model: this.state.globalParameterScreen.modelsList[0].id,
          });
          this.checkIfAllProjectsAreSelected(callback);
        }
      }
      this.state.globalParameterScreen.loadAndSelectModel = false;
    }
    callback(this.state);
  }

  checkIfAllProjectsAreSelected(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.globalParameterScreen.projectsHaveModelsList.length < 1) {
      this.state.globalParameterScreen.allProjectsSelected = false;
      callback(this.state);
      return;
    }
    // Check if all projects from the projectsList are in this selectedModels list
    let allProjectsSelected = true;
    for (const project of this.state.globalParameterScreen
      .projectsHaveModelsList) {
      if (
        !this.state.globalParameterScreen.selectedModels
          .map((x) => {
            return x.project;
          })
          .includes(project)
      ) {
        allProjectsSelected = false;
        break;
      }
    }

    this.state.globalParameterScreen.allProjectsSelected = allProjectsSelected;
    callback(this.state);
  }

  globalParameterScreenOnProjectClick(
    this: AppStateHandler,
    projectId: number,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.globalParameterScreen.selectedProject !== projectId) {
      // Check if project exists
      const projectIndex =
        this.state.globalParameterScreen.projectsList.findIndex((object) => {
          return object.id === projectId;
        });
      if (projectIndex > -1) {
        this.state.globalParameterScreen.selectedProject = projectId;
        this.state.globalParameterScreen.versionsList = [];
        this.state.globalParameterScreen.selectedVersionModel = null;
        this.state.globalParameterScreen.modelsList = [];
        this.state.globalParameterScreen.loadingVersionModels = true;
        this.state.globalParameterScreen.loadingModels = true;
        callback(this.state);

        // Get versions
        this.versionModelApi
          .apiGrexmanagerVersionModelList({
            projectId: this.state.globalParameterScreen.selectedProject,
          })
          .then((versionModelList) => {
            this.state.globalParameterScreen.versionsList = versionModelList;
            if (this.state.globalParameterScreen.versionsList.length >= 1) {
              // check if selected version is in new version list => else select first
              const versionIndex =
                this.state.globalParameterScreen.versionsList.findIndex(
                  (object) => {
                    return (
                      object.id ===
                      this.state.globalParameterScreen.selectedVersionModel
                    );
                  }
                );
              if (
                versionIndex === -1 ||
                this.state.globalParameterScreen.selectedVersionModel == null
              ) {
                this.state.globalParameterScreen.selectedVersionModel =
                  this.state.globalParameterScreen.versionsList[0].id;
              }
            }
            this.state.globalParameterScreen.loadingVersionModels = false;
            callback(this.state);

            // Get Models
            if (
              this.state.globalParameterScreen.selectedVersionModel !== null
            ) {
              this.modelApi
                .apiGrexmanagerModelList({
                  versionModelId:
                    this.state.globalParameterScreen.selectedVersionModel,
                })
                .then((modelsList) => {
                  this.state.globalParameterScreen.modelsList = modelsList;
                  this.state.globalParameterScreen.loadingModels = false;
                  callback(this.state);

                  this.globalParameterScreenSelectAfterLoad(callback);
                })
                .catch((error) => {
                  // TODO: Handle error
                  console.log(error);

                  this.state.globalParameterScreen.errorMessage =
                    "Fout bij het laden van rekenmodellen.";
                  callback(this.state);
                  setTimeout(() => {
                    this.state.globalParameterScreen.errorMessage = "";
                    callback(this.state);
                  }, 5000);
                });
            }
          })
          .catch((error) => {
            // TODO: Handle error
            console.log(error);

            this.state.globalParameterScreen.errorMessage =
              "Fout bij het laden van versies.";
            callback(this.state);
            setTimeout(() => {
              this.state.globalParameterScreen.errorMessage = "";
              callback(this.state);
            }, 5000);
          });
      }
    } else {
      this.globalParameterScreenSelectAfterLoad(callback);
    }
  }

  globalParameterScreenOnVersionModelClick(
    this: AppStateHandler,
    versionId: number,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.globalParameterScreen.selectedVersionModel !== versionId) {
      // Check if version exists
      const versionIndex =
        this.state.globalParameterScreen.versionsList.findIndex((object) => {
          return object.id === versionId;
        });
      if (versionIndex > -1) {
        this.state.globalParameterScreen.selectedVersionModel = versionId;
        // this.state.modelsList = [];
        this.state.globalParameterScreen.loadingModels = true;
        callback(this.state);

        // Get Models
        if (this.state.globalParameterScreen.selectedVersionModel !== null) {
          this.modelApi
            .apiGrexmanagerModelList({
              versionModelId:
                this.state.globalParameterScreen.selectedVersionModel,
            })
            .then((modelsList) => {
              this.state.globalParameterScreen.modelsList = modelsList;
              this.state.globalParameterScreen.loadingModels = false;

              this.globalParameterScreenSelectAfterLoad(callback);

              callback(this.state);
            })
            .catch((error) => {
              // TODO: Handle error
              console.log(error);

              this.state.globalParameterScreen.errorMessage =
                "Fout bij het laden van rekenmodellen";
              callback(this.state);
              setTimeout(() => {
                this.state.globalParameterScreen.errorMessage = "";
                callback(this.state);
              }, 5000);
            });
        }
      }
    } else {
      this.globalParameterScreenSelectAfterLoad(callback);
    }
  }

  globalParameterScreenToggleSelectAllButton(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.checkIfAllProjectsAreSelected(callback);
    if (this.state.globalParameterScreen.allProjectsSelected) {
      // Deselect all
      this.state.globalParameterScreen.selectedModels = [];
      this.state.globalParameterScreen.allProjectsSelected = false;
      callback(this.state);
    } else {
      this.state.globalParameterScreen.loadingSpinner = true;
      callback(this.state);
      this.modelApi.apiGrexmanagerModelList({}).then((modelsList) => {
        this.state.globalParameterScreen.projectsHaveModelsList =
          modelsList.map((model) => model.projectId);
        // Group all the items by projectId
        const groupedByProjectId = modelsList.reduce((acc, model) => {
          if (!acc[model.projectId]) {
            acc[model.projectId] = [];
          }
          acc[model.projectId].push(model);
          return acc;
        }, {} as { [key: number]: ModelListResponse[] });

        // Inside each group, group by the latest versionTimestampLastModified value,
        // and then get the model with the latest modelTimestampLastModified value

        // Select only visible projects
        for (const project of this.state.globalParameterScreen.projectsList) {
          if (project.visible === false && groupedByProjectId[project.id]) {
            delete groupedByProjectId[project.id];
          }
        }
        for (const projectId in groupedByProjectId) {
          const group = groupedByProjectId[projectId];

          // Find the latest versionTimestampLastModified value in the group
          const latestVersionTimestamp = group.reduce((latest, model) => {
            return model.versionTimestampLastModified > latest
              ? model.versionTimestampLastModified
              : latest;
          }, group[0].versionTimestampLastModified);

          // Filter models by the latest versionTimestampLastModified value
          const latestVersionModels = group.filter(
            (model) =>
              model.versionTimestampLastModified === latestVersionTimestamp
          );

          // Find the model with the latest modelTimestampLastModified value in this subset
          const latestModel = latestVersionModels.reduce((latest, model) => {
            return model.modelTimestampLastModified >
              latest.modelTimestampLastModified
              ? model
              : latest;
          });

          // Add this model to the selectedModels
          const selectedModelIds =
            this.state.globalParameterScreen.selectedModels.map(
              (item) => item.model
            );
          if (!selectedModelIds.includes(latestModel.id)) {
            this.state.globalParameterScreen.selectedModels.push({
              project: latestModel.projectId,
              versionModel: latestModel.versionId,
              model: latestModel.id,
            });
          }
          callback(this.state);
        }
        this.state.globalParameterScreen.allProjectsSelected = true;
        this.state.globalParameterScreen.loadingSpinner = false;

        callback(this.state);
      });
    }
  }

  globalParameterScreenToggleModel(
    this: AppStateHandler,
    toggle: {
      project?: number;
      versionModel?: number;
      model?: number;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (toggle.model !== undefined) {
      // check if model is already selected
      if (
        this.state.globalParameterScreen.selectedModels.findIndex(
          (object) => object.model === toggle.model
        ) > -1
      ) {
        // deselect all
        let index = this.state.globalParameterScreen.selectedModels.length - 1;
        while (index >= 0) {
          if (
            this.state.globalParameterScreen.selectedModels[index].model ===
            toggle.model
          ) {
            this.state.globalParameterScreen.selectedModels.splice(index, 1);
            this.checkIfAllProjectsAreSelected(callback);
          }
          index -= 1;
        }
      } else {
        // Select the model
        if (
          this.state.globalParameterScreen.selectedProject &&
          this.state.globalParameterScreen.selectedVersionModel
        ) {
          this.state.globalParameterScreen.selectedModels.push({
            project: this.state.globalParameterScreen.selectedProject,
            versionModel: this.state.globalParameterScreen.selectedVersionModel,
            model: toggle.model,
          });
          this.checkIfAllProjectsAreSelected(callback);
        }
      }
    } else if (toggle.versionModel !== undefined) {
      // check if version is already selected
      if (
        this.state.globalParameterScreen.selectedModels.findIndex(
          (object) => object.versionModel === toggle.versionModel
        ) > -1
      ) {
        // deselect all
        let index = this.state.globalParameterScreen.selectedModels.length - 1;
        while (index >= 0) {
          if (
            this.state.globalParameterScreen.selectedModels[index]
              .versionModel === toggle.versionModel
          ) {
            this.state.globalParameterScreen.selectedModels.splice(index, 1);
            this.checkIfAllProjectsAreSelected(callback);
          }
          index -= 1;
        }
      } else {
        this.state.globalParameterScreen.loadAndSelectModel = true;
      }
    } else if (toggle.project !== undefined) {
      // check if project is already selected
      if (
        this.state.globalParameterScreen.selectedModels.findIndex(
          (object) => object.project === toggle.project
        ) > -1
      ) {
        // deselect all
        let index = this.state.globalParameterScreen.selectedModels.length - 1;
        while (index >= 0) {
          if (
            this.state.globalParameterScreen.selectedModels[index].project ===
            toggle.project
          ) {
            this.state.globalParameterScreen.selectedModels.splice(index, 1);
            this.checkIfAllProjectsAreSelected(callback);
          }
          index -= 1;
        }
      } else {
        this.state.globalParameterScreen.loadAndSelectModel = true;
      }
    }
    callback(this.state);
  }

  globalParameterScreenSubmit(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    // Check if at least one model is selected
    if (this.state.globalParameterScreen.selectedModels.length === 0) {
      this.state.globalParameterScreen.errorMessage =
        "Selecteer minstens één rekenmodel";
      callback(this.state);
      setTimeout(() => {
        this.state.globalParameterScreen.errorMessage = "";
        callback(this.state);
      }, 5000);
      return;
    }

    // Make parameter request
    this.parameterApi
      .apiGrexmanagerParameterCreate({
        parameterCreateRequest: {
          parameters: this.state.globalParameterScreen.parameters.map((p) => {
            return {
              type: p.type,
              rowIndex: p.rowIndex,
              periodType: p.periodType,
              year: p.year,
              valueFloat: p.valueString ? parseFloat(p.valueString) : undefined,
              valueDate: p.valueDate,
            };
          }),
          models: this.state.globalParameterScreen.selectedModels.map(
            (x) => x.model
          ),
        },
      })
      .then(() => {
        this.changeScreen(ScreenState.Projects, callback);
      })
      .catch((error) => {
        this.state.globalParameterScreen.errorMessage =
          "Fout bij het opslaan van de parameters";
        callback(this.state);
        setTimeout(() => {
          this.state.globalParameterScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }
}
