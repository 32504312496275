import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "../../Types";
import { checkPasswordPolicy } from "../../helperFunctions";
export class UserStateHandler {
  loadUserinfo(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.User;
    this.vlaidateNewPasswordRepeat();
    this.state.userNewPasswordScreen.newPasswordinfo = "";
    // UserApi
    this.userApi
      .apiGrexmanagerUserMeRetrieve()
      .then((user) => {
        this.state.currentUser.isSuperAdmin = user.isStaff || false;
        this.state.userScreen = {
          userId: user.id || 0,
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          email: user.email || "",
          mfaVerified: user.mfaVerified || false,
          mfaForced: user.mfaForced || false,
          infoMessage: "",
          errorMessage: "",
        };
        this.state.currentUser.passwordResetRequired =
          user.passwordResetRequired || false;
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);
        this.state.userScreen.errorMessage =
          "Fout bij het laden van gebruikersdata.";
        callback(this.state);
        setTimeout(() => {
          this.state.userScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }

  updateUserForm(
    this: AppStateHandler,
    userinfo: {
      firstName?: string;
      lastName?: string;
      email?: string;
    },

    callback: (newState: AppStateType) => void
  ) {
    if (userinfo.firstName !== undefined) {
      this.state.userScreen.firstName = userinfo.firstName;
    }
    if (userinfo.lastName !== undefined) {
      this.state.userScreen.lastName = userinfo.lastName;
    }
    if (userinfo.email !== undefined) {
      this.state.userScreen.email = userinfo.email;
    }
    callback(this.state);
  }

  updateUser(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.userApi
      .apiGrexmanagerUserMeUpdate({
        grexUserMeUpdate: {
          firstName: this.state.userScreen.firstName,
          lastName: this.state.userScreen.lastName,
          email: this.state.userScreen.email,
        },
      })
      .then(() => {
        this.state.userScreen.infoMessage = "Wijzigingen opgeslagen";
        callback(this.state);
        setTimeout(() => {
          this.state.userScreen.infoMessage = "";
          callback(this.state);
        }, 5000);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.state.userScreen.errorMessage = "Wijzigingen niet opgeslagen";
          callback(this.state);
          setTimeout(() => {
            this.state.userScreen.errorMessage = "";
            callback(this.state);
          }, 5000);
        }
      });
  }

  changePasswordForm(
    this: AppStateHandler,
    password: {
      oldPassword?: string;
      newPassword?: string;
      newPasswordRepeat?: string;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (password.oldPassword !== undefined) {
      this.state.userNewPasswordScreen.oldPassword = password.oldPassword;
    }
    if (password.newPassword !== undefined) {
      this.state.userNewPasswordScreen.newPassword = password.newPassword;
      checkPasswordPolicy(
        this.state.PasswordPolicy.newPasswordRequirements,
        this.state.userNewPasswordScreen.newPassword,
        this.state.PasswordPolicy.notAppliedRequirements
      );
    }
    if (password.newPasswordRepeat !== undefined) {
      this.state.userNewPasswordScreen.newPasswordRepeat =
        password.newPasswordRepeat;
      this.vlaidateNewPasswordRepeat();
    }
    callback(this.state);
  }

  vlaidateNewPasswordRepeat(this: AppStateHandler) {
    if (
      this.state.userNewPasswordScreen.newPassword !==
      this.state.userNewPasswordScreen.newPasswordRepeat
    ) {
      this.state.userNewPasswordScreen.newPasswordError =
        "Wachtwoorden komen niet overeen";
      return false;
    } else {
      this.state.userNewPasswordScreen.newPasswordError = "";
      return true;
    }
  }

  changePassword(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.vlaidateNewPasswordRepeat()) {
      if (this.state.PasswordPolicy.notAppliedRequirements.length > 0) {
        this.state.userNewPasswordScreen.newPasswordError =
          "Wachtwoord voldoet niet aan de eisen.";
        callback(this.state);
        return;
      }
      this.userApi
        .apiGrexmanagerUserMeResetPasswordCreate({
          grexUserChangePassword: {
            currentPassword: this.state.userNewPasswordScreen.oldPassword,
            newPassword: this.state.userNewPasswordScreen.newPassword,
          },
        })
        .then(() => {
          this.loadUserinfo(callback);
          this.state.userNewPasswordScreen.newPasswordError = "";
          this.state.userNewPasswordScreen.newPasswordinfo =
            "Wachtwoord gewijzigd";
          callback(this.state);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.state.userNewPasswordScreen.newPasswordError =
              "Oud wachtwoord is onjuist";
            callback(this.state);
          }
        });
    }
  }

  disableMFA(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.authApi
      .apiGrexmanagerUserMfaDisableCreate()
      .then(() => {
        this.state.userScreen.mfaVerified = false;
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);
        this.state.userScreen.errorMessage =
          "Fout bij het uitzetten van Multi-Factor Authenticatie (MFA).";
        callback(this.state);
        setTimeout(() => {
          this.state.userScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }
  PasswordResetRequired(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.popUpState = PopUpState.PasswordResetRequiredAlert;
    callback(this.state);
  }
}
