import ButtonList from "@shared/components/ButtonList";
import "@shared/components/MessagePopup.css";
import React from "react";
import ProgressBar from "src/components/ProgressBar";

interface InfoMessagePopupProps {
  message: string;
  massageTitle: string;
  progress?: number;
  closeCallback: () => void;
}

export default class InfoMessagePopup extends React.Component<InfoMessagePopupProps> {
  render() {
    return (
      <div id="MessagePopup">
        <div id="MessagePopup-Section">
          <div id="MessagePopup-Title">{this.props.massageTitle}</div>
          <div id="MessagePopup-Text">{this.props.message}</div>
          {(() => {
            if (this.props.progress !== undefined) {
              return (
                <div id="MessagePopup-ProgressBar">
                  <ProgressBar progress={this.props.progress}></ProgressBar>
                </div>
              );
            }
          })()}

          <div id="MessagePopup-Buttons">
            <ButtonList
              data={[
                {
                  title: "Oké",
                  callback: () => {
                    this.props.closeCallback();
                  },
                },
              ]}
            ></ButtonList>
          </div>
        </div>
      </div>
    );
  }
}
