import { ParameterListTypeEnum, PeriodTypeEnum } from "@shared/client/lib";
import { ParameterFormList } from "src/Types";

export const globalParameterDefaultValues: ParameterFormList[] = [
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Uitgangspunt,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per01,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per02,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per03,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per04,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per05,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per06,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per07,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per08,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per09,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per10,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per11,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per12,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per13,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per14,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per15,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per16,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per17,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per18,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per19,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per20,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per21,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per22,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per23,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per24,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per25,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per26,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per27,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per28,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per29,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per30,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per31,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per32,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per33,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per34,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per35,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per36,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per37,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per38,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per39,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentageNegatiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per40,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Uitgangspunt,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per01,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per02,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per03,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per04,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per05,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per06,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per07,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per08,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per09,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per10,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per11,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per12,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per13,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per14,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per15,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per16,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per17,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per18,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per19,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per20,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per21,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per22,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per23,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per24,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per25,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per26,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per27,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per28,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per29,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per30,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per31,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per32,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per33,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per34,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per35,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per36,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per37,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per38,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per39,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RentepercentagePositiefSaldo,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per40,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.RekenrenteContanteWaardeDisconteringsvoet,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Uitgangspunt,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Uitgangspunt,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per01,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per02,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per03,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per04,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per05,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per06,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per07,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per08,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per09,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per10,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per11,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per12,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per13,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per14,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per15,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per16,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per17,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per18,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per19,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per20,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per21,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per22,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per23,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per24,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per25,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per26,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per27,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per28,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per29,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per30,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per31,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per32,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per33,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per34,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per35,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per36,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per37,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per38,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per39,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Kostenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per40,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Uitgangspunt,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per01,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per02,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per03,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per04,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per05,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per06,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per07,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per08,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per09,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per10,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per11,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per12,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per13,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per14,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per15,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per16,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per17,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per18,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per19,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per20,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per21,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per22,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per23,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per24,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per25,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per26,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per27,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per28,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per29,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per30,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per31,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per32,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per33,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per34,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per35,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per36,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per37,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per38,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per39,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.Opbrengstenstijging,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Per40,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.BtwPercentage,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Uitgangspunt,
    year: undefined,
    valueString: "0.21",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.StartdatumGrondexploitatieBoekwaardeVanaf,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Uitgangspunt,
    year: undefined,
    valueString: undefined,
    valueDate: new Date("2020-01-01"),
  },
  {
    type: ParameterListTypeEnum.NcwDatum,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Uitgangspunt,
    year: undefined,
    valueString: undefined,
    valueDate: new Date("2025-01-01"),
  },
  {
    type: ParameterListTypeEnum.Prijspeildatum,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Uitgangspunt,
    year: undefined,
    valueString: undefined,
    valueDate: new Date("2025-01-01"),
  },
  {
    type: ParameterListTypeEnum.Eindwaardedatum,
    rowIndex: undefined,
    periodType: PeriodTypeEnum.Uitgangspunt,
    year: undefined,
    valueString: undefined,
    valueDate: new Date("2030-12-31"),
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per01,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per02,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per03,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per04,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per05,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per06,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per07,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per08,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per09,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per10,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per11,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per12,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per13,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per14,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per15,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per16,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per17,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per18,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per19,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per20,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per21,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per22,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per23,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per24,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per25,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per26,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per27,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per28,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per29,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per30,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per31,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per32,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per33,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per34,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per35,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per36,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per37,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per38,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per39,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.ToekomstigeIndexGebruiker,
    rowIndex: 0,
    periodType: PeriodTypeEnum.Per40,
    year: undefined,
    valueString: "0.02",
    valueDate: undefined,
  },

  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2000,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2001,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2002,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2003,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2004,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2005,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2006,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2007,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2008,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2009,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2010,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2011,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2012,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2013,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2014,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2015,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2016,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2017,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2018,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2019,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2020,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2021,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2022,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2023,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2024,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2025,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2026,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2027,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2028,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2029,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2030,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2031,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2032,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2033,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2034,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2035,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2036,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2037,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2038,
    valueString: "0.02",
    valueDate: undefined,
  },
  {
    type: ParameterListTypeEnum.HistorischeIndexGebruiker,
    rowIndex: 0,
    periodType: undefined,
    year: 2039,
    valueString: "0.02",
    valueDate: undefined,
  },
];
